import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Divider, Typography } from '@material-ui/core';
import { getData } from '../components/fetch';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';

const OrderHistory = ({ history }) => {
    const [orders, setOrders] = useState([]);
    const [last_page, setLast_page] = useState(1);
    const [page, setPage] = useState(1);
    const GetOrders = async (page) => {
        var response = await getData(`api/v1/customer/orders?page=${page}`);
        if (response.ok) {
            setOrders(response.data.data);
            setLast_page(response.data.meta.last_page)
        }
    }
    useEffect(() => {
        GetOrders(page);
        return () => {
            setOrders([]);
        }
    }, [page])
    return (
        <div className="container" style={{ padding: 20 }}>
            <div className='header-title'>Orders</div>
            <div style={{ padding: '10px 0px' }}>
                <Divider style={{ paddingBottom: 1, backgroundColor: 'black' }} />
            </div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Order Date</th>
                            <th>Order Number</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orders.map((order, index) =>
                                <tr key={index}>
                                    <td>{moment(order.order_date).format('DD-MMM-YYYY')}</td>
                                    <td>{order.id}</td>
                                    <td>{order.status}</td>
                                    <td>
                                        <div onClick={() => { history.push(`/MyAccount/Orders/${order.id}`) }} className="button-custom" style={{ maxWidth: 300 }}>View Order Detail</div>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4">
                                <Pagination count={last_page} page={page} onChange={(event, value) => { setPage(value) }} classes={{ root: 'pagination' }} />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}

export default withRouter(OrderHistory);
