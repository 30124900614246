export async function setToken(value) {
    await localStorage.setItem('beautytips_token', value);
}

export function getToken() {
    return localStorage.getItem('beautytips_token');
}

export function removeToken() {
    return localStorage.removeItem('beautytips_token');
}
