import React, { useState } from "react";
import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Toolbar,
    Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { color } from "./common";
import DnsIcon from '@material-ui/icons/Dns';

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none",
        color: "blue",
        fontSize: "20px",
    },
    icon: {
        color: "white"
    }
}));

function DrawerComponent(props) {
    const { categories } = props;
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <>
            <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <ListItem>
                    <ListItemIcon>
                        <DnsIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Category'} primaryTypographyProps={{ style: { fontWeight: 'bold' } }} />
                </ListItem>
                <Divider />
                <List>
                    {
                        categories && categories.map((category, index) =>
                            <ListItem key={index} onClick={() => setOpenDrawer(false)}>
                                <ListItemIcon />
                                <ListItemText primary={category.name} onClick={() => window.location.href = `/bycategory/${category.name}/${category.id}/products`} />
                                {/* <Link primary to={`/bycategory/${category.name}/${category.id}/products/`}>{category.name}</Link>
                                </ListItemText> */}
                            </ListItem>
                        )
                    }
                </List>
                <Divider />
            </Drawer>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        style={{ color: color.primary }}
                        onClick={() => setOpenDrawer(!openDrawer)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="div" style={{ flexGrow: 1, color: color.primary }}>
                        Beauty Tips Cosmetics Gallery
                    </Typography>
                    {/* <Button color="inherit">Login</Button> */}
                </Toolbar>
            </AppBar>
        </>
    );
}
export default DrawerComponent;