import { Typography } from '@material-ui/core'
import React from 'react'
import { withRouter } from 'react-router-dom'

const VerifyEmail = ({ history }) => {
    return (
        <div className="container-md">
            <div className="row">
                <div className="col-12">
                    <div style={{ width: "100%", textAlign: "center",margin: 50 }}>
                        <img src={require("../assets/images/verified.svg")} style={{width: 100,height: 100,marginBottom: 20}}  />
                        <Typography style={{ fontSize: 24, paddingBottom: 12 }}>
                            Your email has been verified. Please sign in.
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(VerifyEmail)
