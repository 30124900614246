import React, { useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  InputBase,
  Divider,
  Link
} from '@material-ui/core'
import { postData } from './fetch';
import AppContext from './AppContext';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export function SignInButton(props) {
  return (
    <Button
      onClick={props.onClick}
      style={{
        fontSize: 12,
        paddingLeft: ".875em",
        paddingRight: ".875em",
        minHeight: 32,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: ".25em",
        paddingBottom: ".25em",
        lineHeight: 1,
        color: "#fff",
        backgroundColor: "#000",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "#000",
        textAlign: "center",
        textDecoration: "none",
        cursor: "pointer",
        fontWeight: 700,
        outline: 0,
        boxSizing: "border-box",
        borderRadius: 99999,
        transition: "background-color .2s",
        minWidth: "149.5px",
      }}
    >
      Sign In
    </Button>
  );
}

export default function SignInDialog(props) {
  const { handleClose, open, onClickCreate } = props;
  const { token, setToken } = useContext(AppContext);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [succeedText, setSucceedText] = useState('');
  const [errorText, setErrorText] = useState('');

  const Login = async (e) => {
    e.preventDefault();
    if (email && password) {
      var response = await postData(`api/v1/login`, { email, password })
      if (response.ok) {
        setErrorText('');
        setToken(response.data.token);
        handleClose();
      } else {
        setSucceedText('');
        setErrorText((response.status === 403 && response.data != null && response.data != undefined) ? (response.data?.message || 'Incorrect email or password') : 'Incorrect email or password');
      }
    }
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
      ></DialogTitle>

      <DialogContent
        style={{
          maxWidth: 472,
          paddingRight: 32,
          paddingBottom: 32,
          paddingLeft: 32,
        }}
      >
        <Typography
          variant="h6"
          style={{
            fontWeight: 700,
            overflowWrap: "break-word",
            marginBottom: 16,
            fontSize: 16,
          }}
        >
          Sign in to Beautytips
        </Typography>
        {
          errorText ? <p style={{ color: 'red', fontSize: 12, textAlign: 'center' }}>{errorText}</p> : succeedText ? <p style={{ fontSize: 12, textAlign: 'center' }}>{succeedText}</p> : ''
        }
        <form onSubmit={Login}>
          <div
            className="col-sm-12 col-md-12 col-lg-12"
            style={{
              display: "flex",
              borderColor: "#ccc",
              width: 408,
              height: "47px",
              border: "1px solid #ccc ",
              borderRadius: 5,
              marginRight: 50,
              padding: 0,
              marginBottom: 16,
            }}
          >
            <InputBase
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                fontSize: 14,
                fontFamily: "inherit",
                margin: 10,
                padding: 0,
              }}
              placeholder="Email Address*"
              inputProps={{
                "aria-label": "Email Address*",
              }}
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
            />
          </div>

          <div
            className="col-sm-12 col-md-12 col-lg-12"
            style={{
              display: "flex",
              borderColor: "#ccc",
              width: 408,
              height: "47px",
              border: "1px solid #ccc ",
              borderRadius: 5,
              marginRight: 50,
              padding: 0,
              marginBottom: 16,
            }}
          >
            <InputBase
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                fontSize: 14,
                fontFamily: "inherit",
                margin: 10,
                padding: 0,
              }}
              placeholder="Password*"
              inputProps={{
                "aria-label": "Password*",
              }}
              type={'password'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
            />
          </div>
          <div className="signInButton">
            <Button
              style={{
                fontSize: 12,
                paddingLeft: 1.25,
                paddingRight: 1.25,
                minHeight: 40,
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "0.5em",
                paddingBottom: "0.5em",
                lineHeight: 1,
                verticalAlign: "middle",
                textAlign: "center",
                textTransform: "uppercase",
                cursor: "pointer",
                fontWeight: 700,
                boxSizing: "border-box",
                color: "rgb(255, 255, 255)",
                backgroundColor: "rgb(0, 0, 0)",
                minWidth: "14.5em",
                display: "inline-flex",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#000",
                textDecoration: "none",
                outline: 0,
                borderRadius: 4,
                transition: "background-color 0.2s ease 0s",
              }}
              type='submit'
            >
              Sign In
            </Button>
          </div>
        </form>
        <Divider
          style={{
            display: "block",
            color: "rgba(0, 0, 0, 0.063)",
            marginLeft: -32,
            marginRight: -32,
            marginTop: 24,
            marginBottom: 24,
            borderBottomWidth: 1,
          }}
        />
        <Typography
          variant="h6"
          style={{
            fontWeight: 700,
            overflowWrap: "break-word",
            marginBottom: 16,
            fontSize: 16,
          }}
        >
          New to Beautytips?
        </Typography>

        <div className="createAccountButton">
          <Button
            style={{
              fontSize: 12,
              paddingLeft: 1.25,
              paddingRight: 1.25,
              minHeight: 40,
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "0.5em",
              paddingBottom: "0.5em",
              lineHeight: 1,
              verticalAlign: "middle",
              textAlign: "center",
              textTransform: "uppercase",
              cursor: "pointer",
              fontWeight: 700,
              boxSizing: "border-box",
              minWidth: "14.5em",
              display: "inline-flex",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#000",
              textDecoration: "none",
              outline: 0,
              borderRadius: 4,
              transition: "background-color 0.2s ease 0s",
            }}
            onClick={onClickCreate}
          >
            Create Account
          </Button>
        </div>
      </DialogContent>
      <div
        style={{
          paddingRight: 32,
          paddingLeft: 32,
          paddingBottom: 32,
        }}
      >
        <Typography
          style={{
            textAlign: "center",

            color: "rgb(117, 117, 117)",
            fontSize: 12,
          }}
        >
          <Link
            style={{
              outline: 0,
              color: "#1470f5",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Terms of Use{" "}
          </Link>{" "}
          &{" "}
          <Link
            style={{
              outline: 0,
              color: "#1470f5",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            Privacy Policy
          </Link>
        </Typography>
      </div>
    </Dialog>
  )
}
