import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Link
} from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CakeIcon from "@material-ui/icons/Cake";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { postData } from './fetch';

const birthDay = [
  { Month: "January", Day: "1" },
  { Month: "February", Day: "2" },
  { Month: "March", Day: "3" },
  { Month: "April", Day: "4" },
  { Month: "May", Day: "5" },
  { Month: "June", Day: "6" },
  { Month: "July", Day: "7" },
  { Month: "August", Day: "8" },
  { Month: "September", Day: "9" },
  { Month: "October", Day: "10" },
  { Month: "November", Day: "11" },
  { Month: "December", Day: "12" },
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderColor: "#ccc",
    width: 300,
    border: "1px solid black",
    borderRadius: 5,
    marginRight: 50,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 8,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));



export function CreateAccountButton(props) {
  return (
    <Button
      onClick={props.onClick}
      style={{
        fontSize: 12,
        paddingLeft: ".875em",
        paddingRight: ".875em",
        minHeight: 32,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "0.25em",
        paddingBottom: "0.25em",
        lineHeight: 1,
        color: "#000",
        backgroundColor: "#fff",
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "#000",
        textAlign: "center",
        textDecoration: "none",
        cursor: "pointer",
        fontWeight: 700,
        outline: 0,
        boxSizing: "border-box",
        borderRadius: 99999,
        transition: "background-color .2s",
        minWidth: "149.5px",
      }}
    >
      Create Account
    </Button>
  );
}

export default function CreateAccountDialog(props) {
  const { open, handleClose } = props;
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    password: "",
    showPassword: false,
  });
  const [succeedText, setSucceedText] = useState('');
  const [errorText, setErrorText] = useState('');

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Register = async (e) => {
    e.preventDefault();
    if (values.name && values.email && values.password) {
      var response = await postData(`api/v1/register`, { name: values.name, email: values.email, password: values.password });
      if (response.ok) {
        setErrorText('');
        setSucceedText(response.data.message);
        ClearAll();
      } else {
        setSucceedText('');
        setErrorText('Email has already been taken.Please try again!');
      }
    }
  }

  const ClearAll = () => {
    setValues({
      name: '',
      email: '',
      password: "",
      showPassword: false,
    })
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Create Account
      </DialogTitle>

      <DialogContent
        dividers
        style={{
          maxWidth: 472,
          paddingRight: 32,
          paddingBottom: 32,
          paddingLeft: 32,
        }}
      >
        <div className="column">
          <div
            className="col-12"
            style={{
              backgroundImage: `url(${require("../assets/images/BeautytipsLogo.jpg")})`,
              width: 100,
              height: 100,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          {/* <Typography
            variant="p"
            component="p"
            style={{
              overflowWrap: "break-word",

              marginBottom: 12,
              fontSize: 14,
              fontFamily:
                "helvetica neue,helvetica,arial,sans-serif",
            }}
          >
            Join the Beauty Insider loyalty program. Earn points,
            redeem rewards, and more.
          </Typography> */}

          {/* <div className="row">
                      <div className="col-6" style={{ paddingRight: 5 }}>
                        <form noValidate autoComplete="off">
                          <div>
                            <TextField
                              required
                              id="outlined-required"
                              label="First name"
                              placeholder="First name"
                              variant="outlined"
                              style={{ marginBottom: 16 }}
                            />
                          </div>
                        </form>
                      </div>
                      <div className="col-6" style={{ paddingLeft: 5 }}>
                        <form noValidate autoComplete="off">
                          <div>
                            <TextField
                              id="outlined-required"
                              label="Last name"
                              placeholder="Last name"
                              variant="outlined"
                              style={{ marginBottom: 16 }}
                            />
                          </div>
                        </form>
                      </div>
                    </div> */}
          <form onSubmit={Register} style={{ paddingTop: 15 }}>
            {
              errorText ? <p style={{ color: 'red', fontSize: 12, textAlign: 'center' }}>{errorText}</p> : succeedText ? <p style={{ fontSize: 12, textAlign: 'center',color: 'green' }}>{succeedText}</p> : ''
            }
            <div className="col-sm-12 col-md-12 col-lg-12" style={{ padding: 0 }}>
              <div noValidate autoComplete="off">
                <div>
                  <TextField
                    id="outlined-email"
                    variant="filled"
                    label="Full Name"
                    type="text"
                    name="name"
                    style={{ width: 408, marginBottom: 16 }}
                    placeholder="Full Name"
                    variant="outlined"
                    fullWidth
                    value={values.name}
                    onChange={handleChange('name')}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12" style={{ padding: 0 }}>
              <div noValidate autoComplete="off">
                <div>
                  <TextField
                    id="outlined-email"
                    variant="filled"
                    label="Email Address"
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: 16 }}
                    value={values.email}
                    onChange={handleChange('email')}
                    required
                  />
                </div>
              </div>
            </div>

            {/* Register PassWord */}

            <div style={{ paddingBottom: 16 }}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                  required
                />
              </FormControl>
              <FormControl></FormControl>
            </div>
            {/* Register PassWord */}

            {/* Birthday */}

            {/* Join Now Button */}
            <div className="signInButton">
              <Button
                style={{
                  fontSize: 12,
                  paddingLeft: 1.25,
                  paddingRight: 1.25,
                  minHeight: 45,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "0.5em",
                  paddingBottom: "0.5em",
                  lineHeight: 1,
                  verticalAlign: "middle",
                  textAlign: "center",
                  textTransform: "uppercase",
                  cursor: "pointer",
                  fontWeight: 700,
                  boxSizing: "border-box",
                  color: "rgb(255, 255, 255)",
                  backgroundColor: "rgb(0, 0, 0)",
                  minWidth: "14.5em",
                  display: "inline-flex",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#000",
                  textDecoration: "none",
                  outline: 0,
                  borderRadius: 4,
                  transition: "background-color 0.2s ease 0s",
                }}
                type={'submit'}
              >
                Join Now
              </Button>
            </div>
            {/* Join Now Button */}
          </form>
          {/* <div
            style={{
              display: "block",
              color: "rgba(0, 0, 0, 0.063)",
              marginBottom: 16,
              marginTop: 24,
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
            }}
          ></div>
          <div
            style={{
              overflowWrap: "break-word",
              color: "rgb(117, 117, 117)",
              marginTop: 4,
              fontSize: 12,
              lineHeight: 1.25,
            }}
          >
            By clicking “Join Now” you acknowledge that you are a
            U.S. or Canada resident and agree to Beautytips’{" "}
            <Link
              style={{
                outline: 0,
                color: "#1470f5",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              Privacy Policy
            </Link>
            ,{" "}
            <Link
              style={{
                outline: 0,
                color: "#1470f5",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              Terms of Use
            </Link>
            ,{" "}
            <Link
              style={{
                outline: 0,
                color: "#1470f5",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              Beauty Insider Terms
            </Link>
            , and to automatically receive Beauty Insider offers
            and notifications via email.
          </div>
          <div
            style={{
              overflowWrap: "break-word",
              color: "rgb(117, 117, 117)",
              marginTop: 16,
              fontSize: 12,
              lineHeight: 1.25,
            }}
          >
            Beautytips uses Google ReCaptcha and by registering,
            users are subject to Google’s{" "}
            <Link
              style={{
                outline: 0,
                color: "#1470f5",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              privacy policy
            </Link>{" "}
            &{" "}
            <Link
              style={{
                outline: 0,
                color: "#1470f5",
                display: "inline-block",
                cursor: "pointer",
              }}
            >
              terms
            </Link>
            ail.
          </div> */}
        </div>
      </DialogContent>
    </Dialog>
  )
}