import React, { useState, useContext, useEffect } from "react";
import "../App.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container, Grid, Badge, Divider, InputBase, NativeSelect, FormControl, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import "../assets/styles/carousel.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { SimpleSlider } from "./RNZoomSlider";
import { Rating } from "../pages/productList";
import { formatMoney, isObjectEmpty } from "./common";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import useStateReducer from "./hooks/useStateReducer";
import { getData } from "./fetch";
import AppContext from "./AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ProductCarousel(props) {
  // const [currentIndex, setCurrentIndex] = useState(0);
  // const owlCarouselRef = useRef(null);

  // const goToNext = () => {
  //   owlCarouselRef.current.next(500);
  // };

  // const goToPrev = () => {
  //   owlCarouselRef.current.prev(500);
  // };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
      <div
        style={{
          marginBottom: "32px",
        }}
      >
        <div>
          <Typography
            style={{
              lineHeight: 1,
              fontFamily: "georgia, times, serif",
              fontSize: 32,
              textAlign: "center",
            }}
          >
            {props.name}
          </Typography>
        </div>
      </div>

      <div className="mycarousel" style={{ marginBottom: 48 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <SimpleSlider
                slidesToShow={props.seriesNum}
                data={props.items}
                sliderProps={{ draggable: false, focusOnSelect: false }}
                component={(item) =>
                  <div
                    className="carousel-quicklook"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <div className="carouselBodyPosition">
                      <Link to={`/product/${item.id}`}>
                        <div
                          className={"my-carousel-item"}
                          style={{
                            backgroundImage: `url(${item.image})`,
                            width: 100,
                            height: 100,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            marginBottom: 12,
                            margin: "auto",
                          }}
                        >
                        </div>

                      </Link>
                      <div
                        className={"carousel-item-hover"}
                        style={{
                          flex: 1,
                          position: "absolute",
                          top: 69,
                          right: 23,
                          width: 100,
                        }}
                      >
                        <div
                          component="div"
                          style={{
                            color: "rgb(255, 255, 255)",
                            display: "flex",
                            flex: 1,
                            justifyContent: "center",
                          }}
                        >
                          <QuickLookBtn item={item} />
                        </div>
                      </div>
                      <Link to={`/product/${item.id}`}>
                        <div
                          style={{
                            display: "flex",
                            height: 24,
                            fontWeight: 700,
                            alignItems: "center",
                            fontSize: 11,
                            color: "black",
                            justifyContent: "center",
                          }}
                        >
                          {item.brand}
                        </div>
                        <div
                          className="productAbout"
                          style={{
                            display: "flex",
                            flex: 1,
                            fontSize: 12,
                            lineHeight: 1.25,
                            // maxHeight: 60,
                            overflow: "hidden",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="caption"
                            style={{
                              fontWeight: 700,
                              lineHeight: 1,
                              textTransform: "uppercase",
                              color: "black",
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{
                              fontWeight: 400,
                              textTransform: "lowercase",
                              color: "black",
                              textAlign: "center",
                            }}
                          >
                            {item.description.length > 35
                              ? item.description.substring(0, 35) + "..."
                              : item.description}
                          </Typography>
                        </div>
                        <Typography
                          variant="caption"
                          style={{
                            fontWeight: 700,
                            textTransform: "lowercase",
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.point}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{
                            fontWeight: 700,
                            textTransform: "lowercase",
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {item.money}
                        </Typography>
                      </Link>
                    </div>

                    {props.buttonType === "signInAccess" ? (
                      <div
                        style={{
                          display: "block",
                          marginTop: 13,
                          paddingBottom: 4,
                          paddingTop: 12,
                        }}
                      >
                        <Button className="HomePageCorouselButtons">
                          Sign in to access
                        </Button>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {props.buttonType === "star" ? (
                      <div
                        style={{
                          display: "block",
                          marginTop: 13,
                          paddingBottom: 4,
                          paddingTop: 12,
                        }}
                      >
                        <Button className="HomePageCorouselButtons">
                          Star
                        </Button>
                      </div>
                    ) : props.buttonType === "add" ? (
                      <div
                        style={{
                          display: "block",
                          marginTop: 13,
                          paddingBottom: 4,
                          paddingTop: 12,
                        }}
                      >
                        <Link
                          style={{
                            fontSize: 12,
                            paddingLeft: "1.25em",
                            paddingRight: "1.25em",
                            paddingTop: "0.5em",
                            paddingBottom: "0.5em",
                            minHeight: 32,
                            alignItems: "center",
                            paddingTop: ".5em",
                            paddingBottom: ".5em",
                            borderStyle: "solid",
                            borderWidth: 1,
                            textTransform: "uppercase",
                            fontWeight: 700,
                            boxSizing: "border-box",
                            borderRadius: 4,
                            color: "#000",
                          }}
                        >
                          Add
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}

                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function QuickLookBtn(props) {
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(props.item.detail);
  const { AddToBasket } = useContext(AppContext);
  const [selectedItem, dispatchSelectedItem] = useStateReducer({
    id: '',
    type: '',
    qty: 1,
    name: '',
    price: 0,
    original_price: null,
    images: [],
    stock: 0,
  })
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const GetProduct = async id => {
    var response = await getData(`api/v1/products/${id}`);
    if (response.ok) {
      setProduct(response.data.data);
    }
  }

  useEffect(() => {
    if (isObjectEmpty(product)) {
      // GetProduct(props.item.id);
      // setProduct(props.item.detail);
    }
  }, [props.item.id])

  useEffect(() => {
    if (!isObjectEmpty(product)) {
      dispatchSelectedItem({ key: 'id', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].id : product.variations[0].id : product.id })
      dispatchSelectedItem({ key: 'type', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? 'size' : 'variation' : 'product' })
      dispatchSelectedItem({ key: 'name', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].name : product.variations[0].color : product.name })
      dispatchSelectedItem({ key: 'price', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].price : product.variations[0].price : product.price })
      dispatchSelectedItem({ key: 'original_price', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].original_price : product.variations[0].original_price : product.original_price })
      dispatchSelectedItem({ key: 'images', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].images : product.variations[0].images : product.images })
      dispatchSelectedItem({ key: 'stock', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].stock : product.variations[0].stock : product.stock })
    }
  }, [product])

  const select = (id, type, name, price, original_price, images, stock) => {
    dispatchSelectedItem({ key: 'id', value: id })
    dispatchSelectedItem({ key: 'type', value: type })
    dispatchSelectedItem({ key: 'name', value: name })
    dispatchSelectedItem({ key: 'price', value: price })
    dispatchSelectedItem({ key: 'original_price', value: original_price })
    dispatchSelectedItem({ key: 'qty', value: 1 })
    dispatchSelectedItem({ key: 'images', value: images })
    dispatchSelectedItem({ key: 'stock', value: stock })
  }

  return (
    <div className="quickLook">
      <Button
        onClick={handleClickOpen}
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 500,
          fontSize: 11,
          backgroundColor: "rgb(0, 0, 0)",
          color: "inherit",
        }}
        component="div"
      >
        QUICK LOOK
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={'lg'}
        classes={{
          paper: "quickLookDialog"
        }}
      >
        <DialogContent>
          <div className="row" style={{ marginBottom: 24 }}>
            <div className="col-md-4">
              {
                selectedItem.images.length > 0 ?
                  // <RNZoomSlider isStickyTop={false} data={selectedItem.images.map(x => ({ image: x.url })) || []} />
                  <Link to={`/product/${props.item.id}`}><img src={`${selectedItem.images[0].url}`} style={{ width: '100%', maxWidth: 300 }} /></Link>
                  :
                  <Link to={`/product/${props.item.id}`}><img src={props.item.image} style={{ width: '100%', maxWidth: 300 }} /></Link>
              }
            </div>
            <div className="col-md-8">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: 16, fontWeight: 'bold' }}>{props.item.brand}</span>
                <span style={{ fontSize: 16, fontWeight: 400 }}>{props.item.name}</span>
                {/* <span style={{ fontSize: 12 }}>
                  SIZE 0.30 oz/ 9 mL<span style={{ margin: "0 0.5em" }}>.</span>ITEM 2363000
                </span> */}
                <span style={{ fontSize: 14, fontWeight: 400, marginTop: 12 }}>{props.item.description}</span>
                <Link to={`/product/${props.item.id}`} style={{ fontSize: 14, marginBottom: 12, textAlign: 'right' }}>See product detail</Link>
                {
                  product.variations?.length > 0 ?
                    <>
                      <span
                        style={{
                          display: "block",
                          minHeight: 35,
                          fontSize: 14,
                          lineHeight: 1.25,
                        }}
                      >
                        TYPE/ COLOR: {selectedItem.name}
                      </span>
                      <div style={{ display: "block", textAlign: "left" }}>
                        <div style={{ display: "block" }}>
                          <div style={{ display: "block", position: "relative" }}>
                            <div style={{ fontSize: 0, margin: "0 -4px" }}>
                              {product.variations?.map((variation, i) =>
                                <div style={{ marginBottom: 12 }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: 4,
                                    }}
                                  >
                                    <Typography
                                      variant="p"
                                      component="div"
                                      style={{ display: "flex", marginRight: 12, fontSize: 14 }}
                                    >
                                      {variation.color}
                                    </Typography>
                                  </div>
                                  {
                                    variation.sizes.map((size, size_index) => (
                                      <>
                                        {
                                          size.images.length > 0 ?
                                            <div
                                              key={size_index}
                                              style={{
                                                display: "inline-block",
                                                position: "relative",
                                                height: 44,
                                                marginRight: 2,
                                              }}
                                              datatype="ProductSwatchItem"
                                              onClick={() => select(size.id, 'size', size.name, size.price, size.original_price, size.images, size.stock)}
                                            >
                                              <div className="product-color">
                                                <div className="product-color-div" style={(selectedItem.type === "size" && selectedItem.id === size.id) ? { border: '3px solid black', borderRadius: 7 } : {}}>
                                                  <img src={`${size.images[0].url}`} style={{ width: 36, height: 36, objectFit: 'cover', borderRadius: 5 }} />
                                                </div>
                                              </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                      </>
                                    ))
                                  }
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </> : <></>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <Rating />
                <span style={{ fontSize: 12, fontWeight: 'bold', display: 'flex', margin: '0 10px' }}>1 review</span>
                <div style={{ borderLeft: '1px solid rgb(204, 204, 204)', height: 13.5, marginRight: 10 }}></div>
                <span style={{ fontSize: 12, fontWeight: 'bold', display: 'flex' }}>17.8K loves</span>
              </div> */}
            </div>
            <div className="col-md-8">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                  {
                    selectedItem.original_price ?
                      <div style={{ textDecorationLine: 'line-through', color: 'red', textDecorationStyle: 'solid' }} >{formatMoney(selectedItem.original_price)} MMK</div>
                      :
                      <></>
                  }
                  {formatMoney(selectedItem.price)} MMK
                </span>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      flexBasis: "auto",
                      display: "block",
                      minWidth: 0,
                    }}
                  >
                    <FormControl>
                      <NativeSelect
                        id="demo-customized-select-native"
                        value={selectedItem.qty}
                        onChange={e => dispatchSelectedItem({ key: 'qty', value: e.target.value })}
                        input={<BootstrapInput />}
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      flexBasis: "auto",
                      display: "block",
                      minWidth: 0,
                      width: 160,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginLeft: 8,
                        marginRight: 8,
                        flexFlow: "row wrap",
                      }}
                    >
                      <div
                        style={{
                          display: "block",
                          minWidth: 0,
                          width: "100%",
                          flex: 1,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ width: 159, height: 40 }}
                          onClick={() => {
                            setLoading(true);
                            AddToBasket(
                              {
                                selectedItem,
                                fnc: (isSucceed) => {
                                  setLoading(false);
                                  setOpen(!isSucceed);
                                }
                              }
                            )
                          }}
                        >
                          {loading ? <CircularProgress color="info" size={16} /> :
                            'Add to Basket'
                          }
                        </Button>
                      </div>
                      {/* <div style={{ display: "block", marginTop: 8 }}>
                        <Button
                          variant="outlined"
                          style={{
                            width: 159,
                            height: 40,
                            borderColor: "#000",
                          }}
                        >
                          <FavoriteBorderOutlinedIcon
                            fontSize="small"
                            style={{ marginRight: 6.75 }}
                          />
                          <Typography variant="p">ADD TO LOVES</Typography>
                        </Button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const BootstrapInput = withStyles((theme) => ({
  root: {
    height: 40,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);