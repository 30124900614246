import React, { useState, useRef, createRef, useLayoutEffect, useEffect, useContext } from "react";
import "../App.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  Container,
  Typography,
  Grid,
  Badge,
  Button,
  FormControl,
  NativeSelect,
  InputBase,
  Tabs,
  Tab,
  Box,
  AppBar,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import TabList from "@material-ui/lab/TabList";
import "../assets/styles/carousel.css";
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import StarIcon from "@material-ui/icons/Star";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { ProductPageDialog } from "../components/subject";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "../ColorImage/color1.jpg";
import "../assets//images/p10.jpg";
import PropTypes from "prop-types";
import { pink } from "@material-ui/core/colors";
import { ProductCarousel } from "../components/carousel";
import RNZoomSlider from "../components/RNZoomSlider";
import { getData, postData } from "../components/fetch";
import { API_URL, font, formatMoney, isObjectEmpty } from "../components/common";
import useStateReducer from '../components/hooks/useStateReducer';
import AppContext from "../components/AppContext";

const BootstrapInput = withStyles((theme) => ({
  root: {
    height: 40,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export default function ItemPage(props) {
  const { AddToBasket } = useContext(AppContext);
  const classes = useStyles();
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedItem, dispatchSelectedItem] = useStateReducer({
    id: '',
    type: '',
    qty: 1,
    name: '',
    price: 0,
    original_price: null,
    images: [],
    stock: 0,
  })
  const [zipcode, setZipcode] = React.useState("");
  const color = "COLOR: 330W  - medium skin with a warm yellow undertone";

  const [value, setValue] = React.useState(0);

  const tabsClick = (event, newValue) => {
    setValue(newValue);
  };

  const [seriesNum, setSeriesNum] = useState(6);

  useLayoutEffect(() => {
    setSeriesNum(
      window.innerWidth <= 450 && window.innerHeight >= 200
        ? 1
        : window.innerWidth == 760
          ? 3
          : 6
    );
  }, [window.innerWidth]);

  const GetProduct = async id => {
    var response = await getData(`api/v1/products/${id}`);
    if (response.ok) {
      setProduct(response.data.data);
    }
  }

  useEffect(() => {
    GetProduct(id);
  }, [id])

  useEffect(() => {
    if (!isObjectEmpty(product)) {
      dispatchSelectedItem({ key: 'id', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].id : product.variations[0].id : product.id })
      dispatchSelectedItem({ key: 'type', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? 'size' : 'variation' : 'product' })
      dispatchSelectedItem({ key: 'name', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].name : product.variations[0].color : product.name })
      dispatchSelectedItem({ key: 'price', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].price : product.variations[0].price : product.price })
      dispatchSelectedItem({ key: 'original_price', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].original_price : product.variations[0].original_price : product.original_price })
      dispatchSelectedItem({ key: 'images', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].images : product.variations[0].images : product.images })
      dispatchSelectedItem({ key: 'stock', value: product.variations.length > 0 ? product.variations[0].sizes.length > 0 ? product.variations[0].sizes[0].stock : product.variations[0].stock : product.stock })
    }
  }, [product])

  const select = (id, type, name, price, original_price, images, stock) => {
    dispatchSelectedItem({ key: 'id', value: id })
    dispatchSelectedItem({ key: 'type', value: type })
    dispatchSelectedItem({ key: 'name', value: name })
    dispatchSelectedItem({ key: 'price', value: price })
    dispatchSelectedItem({ key: 'original_price', value: original_price })
    dispatchSelectedItem({ key: 'qty', value: 1 })
    dispatchSelectedItem({ key: 'images', value: images })
    dispatchSelectedItem({ key: 'stock', value: stock })
  }

  return (
    <div className="container-md">
      <div className="row" style={{ padding: 15, margin: 0 }}>
        {/* <Link>
          <Typography style={{ fontSize: 12, color: "#000" }}>
            Bath & Body{" "}
            <ArrowForwardIosIcon
              style={{ fontSize: ".5em", marginLeft: 8, marginRight: 8 }}
            />
          </Typography>
        </Link>

        <Link>
          <Typography style={{ fontSize: 12, color: "#000" }}>
            Body Moisturizers{" "}
            <ArrowForwardIosIcon
              style={{ fontSize: ".5em", marginLeft: 8, marginRight: 8 }}
            />
          </Typography>
        </Link>

        <Link>
          <Typography style={{ fontSize: 12, color: "#757575" }}>
            Body Lotions & Body Oils{" "}
          </Typography>
        </Link> */}
      </div>

      <div
        className="column"
        style={{
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              {
                selectedItem.images ?
                  <RNZoomSlider isStickyTop={false} data={selectedItem.images.map(x => ({ image: x.url })) || []} />
                  :
                  <RNZoomSlider isStickyTop={false} data={product?.images?.map(x => ({ image: x.url })) || []} />
              }
              {/* <div
                style={{
                  backgroundImage: `url(${require("../assets/images/productItem.jpg")})`,
                  position: "relative",
                  minHeight: 300,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div style={{ display: "flex", flex: 1, marginBottom: 96 }}>
                  <Badge
                    component="div"
                    style={{
                      color: "rgb(255, 255, 255)",
                      display: "flex",
                      flex: 1,
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 20,
                        backgroundColor: "rgb(0, 0, 0)",
                        marginBottom: 2,
                        fontSize: 10,

                        padding: "0px 4px",
                      }}
                      component="div"
                    >
                      NEW
                    </Typography>
                  </Badge>
                </div>
              </div> */}
            </div>

            <div className="col-lg-8 col-md-4 col-sm-12">
              <div
                className="row"
                style={{
                  marginBottom: 12,
                }}
              >
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <Typography
                    variant="h1"
                    style={{
                      display: "flex",

                      flexDirection: "column",
                      fontSize: 16,
                      lineHeight: 1.25,
                    }}
                  >
                    <Link style={{ color: "#000" }} to={`/bybrand/${product.brand?.name}/${product.brand?.id}`}>
                      <span
                        style={{
                          fontWeight: 700,
                          textTransform: "uppercase",
                          fontFamily:
                            font.primary,
                        }}
                      >
                        {product.brand?.name}
                      </span>
                    </Link>
                    <span style={{ fontSize: 16, fontWeight: 400 }}>
                      {product.name}
                    </span>
                  </Typography>
                  <Typography
                    style={{
                      display: "block",

                      color: "#757575",
                      marginBottom: 12,
                      marginTop: 4,
                      fontSize: 12,
                    }}
                    variant="div"
                    component="div"
                  >
                    {product.short_description}
                    {/* <span style={{ margin: "0 0.5em" }}>.</span>
                    ITEM 2363000 */}
                  </Typography>
                  {/* <div
                    style={{
                      display: "flex",

                      fontWeight: 700,
                      flexWrap: "wrap",
                      alignItems: "center",
                      fontSize: 12,
                      lineHeight: 1,
                    }}
                  >
                    <Link style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          color: "#ccc",
                          fontSize: 12,
                          width: 75.02,
                          height: 15,
                          position: "relative",
                          overflow: "hidden",
                          lineHeight: 0,
                          textAlign: "left",
                        }}
                      >
                        <div style={{ display: "flex", flex: 1 }}>
                          <StarIcon
                            style={{ width: 15, height: 15, color: "#000" }}
                            fontSize="inherit"
                          />
                          <StarIcon
                            style={{ width: 15, height: 15, color: "#000" }}
                            fontSize="small"
                          />
                          <StarIcon
                            style={{ width: 15, height: 15, color: "#000" }}
                            fontSize="small"
                          />
                          <StarIcon
                            style={{ width: 15, height: 15, color: "#000" }}
                            fontSize="small"
                          />
                          <StarIcon
                            style={{ width: 15, height: 15 }}
                            fontSize="small"
                          />
                        </div>
                      </div>
                      <span style={{ color: "#000", marginLeft: ".5em" }}>
                        2 reviews
                      </span>
                    </Link>
                    <div
                      style={{
                        display: "flex",
                        height: "1.125em",
                        borderColor: "#ccc",
                        marginLeft: ".75em",
                        marginRight: ".75em",
                        borderLeftWidth: "1px",
                        borderLeftStyle: "solid",
                      }}
                    ></div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FavoriteIcon
                        style={{ width: 15, height: 15 }}
                        fontSize="inherit"
                      />
                      <span style={{ marginLeft: ".5em" }}>
                        <span>38.2K </span>
                        loves
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontWeight: 700,
                      marginTop: 8,
                      fontSize: 12,
                      lineHeight: 1.25,
                    }}
                  >
                    exclusive
                  </div>
                 */}
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12">
                  {
                    selectedItem.original_price ?
                      <div
                        style={{ fontWeight: 500, fontSize: 14, lineHeight: 1.25 }}
                      >
                        <span style={{ textDecorationLine: 'line-through', color: 'red', textDecorationStyle: 'solid' }} >{formatMoney(selectedItem.original_price)} MMK</span>
                      </div>
                      :
                      <></>
                  }
                  <div
                    style={{ fontWeight: 700, fontSize: 16, lineHeight: 1.25 }}
                  >
                    <span>{formatMoney(selectedItem.price)} MMK</span>
                  </div>
                  {/* <Typography
                    variant="p"
                    component="p"
                    style={{
                      fontWeight: 700,
                      textTransform: "uppercase",
                      fontSize: 12,
                      lineHeight: 1.25,
                      width: 112,
                      marginTop: 8,
                    }}
                  >
                    spend $50 for free shipping
                  </Typography> */}
                  {/* <div
                    style={{
                      display: "inline-block",
                      color: "rgb(102, 102, 102)",
                      marginTop: 8,
                      fontSize: 12,
                      lineHeight: 1.25,
                    }}
                  >
                    <Typography
                      variant="p"
                      component="p"
                      style={{ overflowWrap: "break-word", marginBottom: 4 }}
                    >
                      Pay in 4 interest-free payments of $5.50
                    </Typography>
                  </div> */}

                  {/* <ProductPageDialog /> */}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="row">
                    <div
                      style={{
                        flexBasis: "auto",
                        display: "block",
                        minWidth: 0,
                      }}
                    >
                      <FormControl className={classes.margin}>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={selectedItem.qty}
                          onChange={(e) => dispatchSelectedItem({ key: 'qty', value: e.target.value })}
                          input={<BootstrapInput />}
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        flexBasis: "auto",
                        display: "block",
                        minWidth: 0,
                        width: 160,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginLeft: 8,
                          marginRight: 8,
                          flexFlow: "row wrap",
                        }}
                      >
                        <div
                          style={{
                            display: "block",
                            minWidth: 0,
                            width: "100%",
                            flex: 1,
                          }}
                        >
                          {
                            product &&
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ width: 159, height: 40 }}
                              disabled={loading || !product.total_stock > 0}
                              onClick={() => {
                                setLoading(true);
                                AddToBasket(
                                  {
                                    selectedItem,
                                    fnc: () => setLoading(false)
                                  }
                                )
                              }}
                            >
                              {loading ? <CircularProgress color="info" size={16} /> :
                                product.total_stock && product.total_stock > 0 ? 'Add to Basket' : 'Out of Stock'
                              }
                            </Button>
                          }
                        </div>
                        {/* <div style={{ display: "block", marginTop: 8 }}>
                          <Button
                            variant="outlined"
                            style={{
                              width: 159,
                              height: 40,
                              borderColor: "#000",
                            }}
                          >
                            <FavoriteBorderOutlinedIcon
                              fontSize="small"
                              style={{ marginRight: 6.75 }}
                            />
                            <Typography variant="p">ADD TO LOVES</Typography>
                          </Button>
                        </div>
                        <div
                          style={{
                            display: "block",
                            marginTop: 24,
                          }}
                        >
                          <div style={{ display: "block" }}>
                            <label
                              style={{
                                display: "block",
                                fontWeight: 700,
                                marginBottom: ".5em",
                                fontSize: 12,
                                lineHeight: 1.25,
                              }}
                            >
                              Find in store
                            </label>
                            <div
                              style={{
                                display: "flex",
                                borderColor: "#ccc",
                                width: 160,
                                height: 40,
                                border: "1px solid ",
                                borderRadius: 5,
                                marginRight: 50,
                              }}
                            >
                              <InputBase
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flex: 1,
                                  fontSize: 14,
                                  fontFamily: "inherit",
                                  margin: 10,
                                }}
                                placeholder="Enter ZIP/Postal code"
                                inputProps={{
                                  "aria-label": "Enter ZIP/Postal code",
                                }}
                                value={zipcode}
                                onChange={(e) => {
                                  if (e.target.value.length <= 4) {
                                    setZipcode(e.target.value);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                product.variations?.length > 0 ?
                  <>
                    <span
                      style={{
                        display: "block",
                        minHeight: 35,
                        fontSize: 14,
                        lineHeight: 1.25,
                      }}
                    >
                      TYPE/ COLOR: {selectedItem.name}
                    </span>
                    <div style={{ display: "block", textAlign: "left" }}>
                      <div style={{ display: "block" }}>
                        <div style={{ display: "block", position: "relative" }}>
                          <div style={{ fontSize: 0, margin: "0 -4px" }}>
                            {product.variations?.map((variation, i) =>
                              <div style={{ marginBottom: 12 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 4,
                                  }}
                                >
                                  <Typography
                                    variant="p"
                                    component="div"
                                    style={{ display: "flex", marginRight: 12, fontSize: 14 }}
                                  >
                                    {variation.color}
                                  </Typography>
                                </div>
                                {
                                  variation.sizes.map((size, size_index) => (
                                    <>
                                      {
                                        size.images.length > 0 ?
                                          <div
                                            key={size_index}
                                            style={{
                                              display: "inline-block",
                                              position: "relative",
                                              height: 44,
                                              marginRight: 2,
                                            }}
                                            datatype="ProductSwatchItem"
                                            onClick={() => select(size.id, 'size', size.name, size.price, size.original_price, size.images, size.stock)}
                                          >
                                            <div className="product-color">
                                              <div className="product-color-div" style={(selectedItem.type === "size" && selectedItem.id === size.id) ? { border: '3px solid black', borderRadius: 7 } : {}}>
                                                <img src={`${size.images[0].url}`} style={{ width: 36, height: 36, objectFit: 'cover', borderRadius: 5 }} />
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <></>
                                      }
                                    </>
                                  ))
                                }
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </> : <></>
              }
              {/* <div
                style={{
                  borderColor: "#eee",
                  marginTop: 32,
                  paddingTop: 16,
                  borderTopWidth: 1,
                  borderTopStyle: "solid",
                }}
              >
                <Typography
                  style={{
                    display: "block",
                    fontWeight: 700,
                    marginBottom: 16,
                    fontSize: 16,
                  }}
                >
                  Use It With
                </Typography>
                <div className="row" style={{ margin: 0 }}>
                  <Link
                    className="col-lg-6 col-md-12 col-sm-12"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      padding: "16px 8px",
                      border: "1px solid #000",
                      borderRadius: 4,
                      marginRight: 4,
                    }}
                  >
                    <div
                      className={"carousel-item"}
                      style={{
                        display: "flex",
                        flex: 1,
                        color: "#000",
                        backgroundImage: `url(${require("../assets/images/p10.jpg")})`,
                        width: "100%",
                        minWidth: 70,
                        height: 70,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        marginRight: 8,
                      }}
                    >
                      <div
                        className={"carousel-item-hover"}
                        style={{ flex: 1, marginTop: 43 }}
                      >
                        <Badge
                          component="div"
                          style={{
                            color: "rgb(255, 255, 255)",
                            display: "flex",
                            flex: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <Link
                            style={{
                              display: "flex",
                              flex: 1,
                              alignItems: "center",
                              lineHeight: 1,
                              color: "rgb(255, 255, 255)",
                              paddingTop: 8,
                              paddingBottom: 8,
                              fontSize: 11,
                              backgroundColor: "#000",
                            }}
                            component="div"
                          >
                            QUICK LOOK
                          </Link>
                        </Badge>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 4,
                        color: "#000",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 12,
                          lineHeight: 1.25,
                          overflow: "hidden",
                          maxHeight: 45,
                        }}
                      >
                        <span
                          style={{
                            lineHeight: 1,
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          Anastasia Beverly Hills
                        </span>
                        <br />
                        <span>Luminous Foundation</span>
                      </div>

                      <span
                        style={{
                          maxWidth: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",

                          display: "block",
                          wordWrap: "break-word",
                          color: "#757575",
                          fontSize: 12,
                          lineHeight: 1.25,
                        }}
                      >
                        {color.length > 35
                          ? color.substring(0, 35) + "..."
                          : color}
                      </span>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "auto",
                          paddingTop: 8,
                        }}
                      >
                        <div
                          style={{
                            display: "block",
                            fontWeight: 700,
                            flexShrink: 0,
                            marginRight: 12,
                            fontSize: 12,
                            lineHeight: 1.25,
                          }}
                        >
                          <span>$38.00</span>
                        </div>
                        <Link
                          style={{
                            fontSize: 12,
                            paddingLeft: ".875em",
                            paddingRight: ".875em",
                            minHeight: 32,
                            alignItems: "center",
                            paddingTop: ".5em",
                            paddingBottom: ".5em",
                            borderStyle: "solid",
                            borderWidth: 1,
                            textTransform: "uppercase",
                            fontWeight: 700,
                            boxSizing: "border-box",
                            borderRadius: 4,
                            color: "#000",
                          }}
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  </Link>
                  <Link
                    className="col-lg-6 col-md-12 col-sm-12"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      padding: "16px 8px",
                      border: "1px solid #000",
                      borderRadius: 4,
                      marginLeft: 4,
                    }}
                  >
                    <div
                      className={"carousel-item"}
                      style={{
                        display: "flex",
                        flex: 1,
                        color: "#000",
                        backgroundImage: `url(${require("../assets/images/p10.jpg")})`,
                        width: "100%",
                        minWidth: 70,
                        height: 70,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        marginRight: 8,
                      }}
                    >
                      <div
                        className={"carousel-item-hover"}
                        style={{ flex: 1, marginTop: 43 }}
                      >
                        <Badge
                          component="div"
                          style={{
                            color: "rgb(255, 255, 255)",
                            display: "flex",
                            flex: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <Link
                            style={{
                              display: "flex",
                              flex: 1,
                              alignItems: "center",
                              lineHeight: 1,
                              color: "rgb(255, 255, 255)",
                              paddingTop: 8,
                              paddingBottom: 8,
                              fontSize: 11,
                              backgroundColor: "#000",
                            }}
                            component="div"
                          >
                            QUICK LOOK
                          </Link>
                        </Badge>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 4,
                        color: "#000",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 12,
                          lineHeight: 1.25,
                          overflow: "hidden",
                          maxHeight: 45,
                        }}
                      >
                        <span
                          style={{
                            lineHeight: 1,
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          Anastasia Beverly Hills
                        </span>
                        <br />
                        <span>Luminous Foundation</span>
                      </div>

                      <span
                        style={{
                          maxWidth: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",

                          display: "block",
                          wordWrap: "break-word",
                          color: "#757575",
                          fontSize: 12,
                          lineHeight: 1.25,
                        }}
                      >
                        {color.length > 35
                          ? color.substring(0, 35) + "..."
                          : color}
                      </span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "auto",
                          paddingTop: 8,
                        }}
                      >
                        <div
                          style={{
                            display: "block",
                            fontWeight: 700,
                            flexShrink: 0,
                            marginRight: 12,
                            fontSize: 12,
                            lineHeight: 1.25,
                          }}
                        >
                          <span>$38.00</span>
                        </div>
                        <Link
                          style={{
                            fontSize: 12,
                            paddingLeft: ".875em",
                            paddingRight: ".875em",
                            minHeight: 32,
                            alignItems: "center",
                            paddingTop: ".5em",
                            paddingBottom: ".5em",
                            borderStyle: "solid",
                            borderWidth: 1,
                            textTransform: "uppercase",
                            fontWeight: 700,
                            boxSizing: "border-box",
                            borderRadius: 4,
                            color: "#000",
                          }}
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              </div> */}
              <div style={{ marginTop: 32 }}>
                <div
                  style={{ width: "100%", backgroundColor: "white", }}
                >
                  <AppBar className="row" position="static" style={{ overflow: 'auto' }}>
                    <Tabs
                      value={value}
                      onChange={tabsClick}
                      variant="fullWidth"
                      scrollButtons="off"
                    >
                      <Tab
                        label="Details"
                        style={{
                          fontSize: 12, maxWidth: 150,
                        }}
                      />
                      {/* <Tab label="About The Brand" style={{ fontSize: 12, maxWidth: 150 }} /> */}
                      {/* <Tab label="Shipping & Returns" style={{ fontSize: 12, maxWidth: 200 }} /> */}
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <div className="full_description" dangerouslySetInnerHTML={{ __html: product.full_description }} />
                  </TabPanel>
                  {/* <TabPanel value={value} index={1}>
                    <p>
                      As the #1 professional hair care brand worldwide, Kérastase has pioneered luxury care since 1964. Inspired by our intimate knowledge of the modern woman, Kérastase brings together innovative, bespoke products with a professional diagnostic approach to treat each woman’s individual hair needs.
                    </p>
                  </TabPanel> */}
                  <TabPanel value={value} index={1}>
                    <p>
                      Get more information about <Link to="#">shipping rates, schedules, methods, restrictions</Link> and <Link to="#">International Shipping</Link>.
                    </p>
                    <p>
                      If you are not completely satisfied with an online purchase or gift, you may return your U.S.or Canadian purchase in stores or by mail. Restrictions apply for returns made outside the U.S.
                    </p>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Divider className="DividerLine" />
        <div>
          <ProductCarousel
            carouselLoop={5}
            seriesNum={seriesNum}
            buttonType={"star"}
            name="Similar Products"
            items={[
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$100.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$50.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$100.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$50.00",
              },
              {
                image: require("../assets/images/p2.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$100.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$100.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$50.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$100.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$50.00",
              },
              {
                image: require("../assets/images/p2.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$100.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$100.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$50.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$100.00",
              },
              {
                image: require("../assets/images/p1.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$50.00",
              },
              {
                image: require("../assets/images/p2.jpg"),
                Subject: "online only",
                name: "Sol de Janeiro",
                description: "Brazilian Bum Bum Cream",
                money: "$100.00",
              },
            ]}
          />
        </div> */}

        {/* Explore This Product */}

        {/* <Divider className="DividerLine" />

        <div>
          <ProductCarousel
            carouselLoop={5}
            seriesNum={seriesNum}

            buttonType={'star'}
            name="Similar Products"
            items={[]]}
          />
        </div> */}

        {/* <Divider className="DividerLine" /> */}
        {/* <div>
          <ProductCarousel
            carouselLoop={5}
            seriesNum={5}
            buttonType={'star'}
            owlProps={"owl-theme"}
            name="Similar Products"
            items={[]}
          />
        </div> */}

        {/* Recently Viewed */}

        {/* <Divider className="DividerLine" /> */}

        {/* <Typography
          variant="h2"
          component="h2"
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            marginBottom: 16,
            fontFamily: "georgia, times, serif",
            fontSize: 24,
          }}
        >
          Recently Viewed
        </Typography> */}

        {/* <div></div> */}

        {/* Related Pages */}
        {/* <Divider className="DividerLine" />
        <Typography
          variant="h2"
          component="h2"
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            marginBottom: 16,
            fontFamily: "georgia, times, serif",
            fontSize: 24,
          }}
        >
          Related Pages
        </Typography>
        <Box className="itemFlexBox">
          <Link className="itempagefooter">beautiful palettes</Link>
          <Link className="itempagefooter">best holiday makeup</Link>
          <Link className="itempagefooter">color correcting palettes</Link>
          <Link className="itempagefooter">glam makeup</Link>
          <Link className="itempagefooter">holiday makeup</Link>
          <Link className="itempagefooter">makeup with sun protection</Link>
          <Link className="itempagefooter">new orleans makeup</Link>
          <Link className="itempagefooter">pretty makeup</Link>
          <Link className="itempagefooter">rose makeup</Link>
          <Link className="itempagefooter">urban decay smoky palette</Link>
        </Box> */}
      </div>
    </div >
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ccc",
    height: 40,
    borderRadius: "5px 0px 0px 5px",
  },
  input: {
    marginLeft: theme.spacing(1.5),
    flex: 1,
  },
  iconButton: {
    padding: 8,
  },
  margin: {
    width: 54,
  },
}));

// TabsComponents
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
