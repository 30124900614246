import { Badge, Button, Checkbox, FormControlLabel, Radio, RadioGroup, Slider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Link, useParams, withRouter } from 'react-router-dom';
import { getData } from '../components/fetch';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { formatMoney } from '../components/common';
import ArrayFnc from '../components/array';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Pagination } from '@material-ui/lab';

const ProductList = (props) => {
    const min = 0, max = 300000000;
    const { history, location } = props;
    const name = location.state?.search || '';
    const { brand_name, brand_id, category_name, category_id, sub_category_name, sub_category_id } = useParams();
    const [brands, setBrands] = useState([]);
    const [brandValue, setBrandValue] = useState('');
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [priceRange, setPriceRange] = useState([min, max]);
    const [price, setPrice] = useState('');
    const [total, setTotal] = useState(0);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [last_page, setLast_page] = useState(1);
    const [page, setPage] = useState(1);

    const GetProducts = async () => {
        var qs = '';
        if (page) {
            qs += `&page=${page}`;
        }
        if (name) {
            qs += `&name=${name}`;
        }
        if (category_id) {
            qs += '&category_ids[0]=' + category_id;
        }
        if (sub_category_id) {
            qs += '&sub_category_ids[0]=' + sub_category_id;
        }
        if (brand_id) {
            qs += '&brand_ids[${i}]=' + brand_id;
        }
        for (var i = 0; i < selectedBrands.length; i++) {
            qs += `&brand_ids[${i}]=${selectedBrands[i]}`;
        }
        if (priceRange[0]) {
            qs += `&min_price=${priceRange[0]}`;
        }
        if (priceRange[1]) {
            qs += `&max_price=${priceRange[1]}`;
        }
        var response = await getData(`api/v1/products?${qs}`);
        setLoading(false);
        if (response.ok) {
            setProducts(ArrayFnc.clone(response.data.data.map(x => ({
                id: x.id,
                image: x.images && x.images.length > 0 ? x.images[0].url : '/assets/images/no-image.jpg',
                Subject: x.brand,
                name: x.name,
                brandName: x.brand ? x.brand.name : '',
                description: x.short_description,
                price: x.price,
                original_price: x.original_price,
            }))));
            setTotal(response.data.meta.total);
            setLast_page(response.data.meta.last_page);
        }
    }
    const GetBrands = async () => {
        var response = await getData(`api/v1/brands/all`);
        if (response.ok) {
            setBrands(response.data.data);
        }
    }
    useEffect(() => {
        GetBrands();
    }, [])

    useEffect(() => {
        GetProducts();
    }, [page, name, brand_id, category_id, sub_category_id, selectedBrands, priceRange])

    useEffect(() => {
        if (price) {
            setPriceRange(ArrayFnc.clone(price.split('-')));
        } else {
            setPriceRange(ArrayFnc.clone([min, max]));
        }
    }, [price])

    return (
        <div className="container-md">
            <div className="row">
                <div className="col-12" style={{ height: 50 }}>
                    <span style={{ fontSize: 12, margin: '13px 0', display: 'flex', alignItems: 'center' }}>{category_name}
                        {sub_category_name ?
                            <><ArrowForwardIosIcon
                                style={{ fontSize: 20, fontWeight: 700, marginLeft: 8, marginRight: 8 }}
                            /> {sub_category_name}</>
                            : ''}
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: "24px 0" }}>
                        <span style={{ fontSize: 14, fontWeight: 'bold', paddingTop: 2 }}>Filter By:</span>
                        <Link to="#" onClick={() => { setSelectedBrands(ArrayFnc.clone([])); setPriceRange(ArrayFnc.clone([min, max])); }}><span style={{ fontSize: 12 }}>Reset all filters</span></Link>
                    </div>
                    {
                        !brand_id &&
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                                <span style={{ fontSize: 12, fontWeight: 'bold', paddingTop: 4 }}>Brand</span>
                                <Link to="#" onClick={() => { setSelectedBrands(ArrayFnc.clone([])) }}><span style={{ fontSize: 12 }}>Reset</span></Link>
                            </div>
                            <div className='filter-box'>
                                {
                                    brands.map((brand, index) =>
                                        <FormControlLabel key={index}
                                            control={
                                                <Checkbox
                                                    checked={selectedBrands.includes(brand.id)}
                                                    onChange={(e, value) => {
                                                        var _selectedBrands = selectedBrands;
                                                        if (_selectedBrands.includes(brand.id)) {
                                                            _selectedBrands = ArrayFnc.arrayRemove(_selectedBrands, brand.id);
                                                        } else {
                                                            _selectedBrands.push(brand.id);
                                                        }
                                                        setSelectedBrands(ArrayFnc.clone(_selectedBrands));
                                                    }}
                                                    value={brand.id}
                                                    name="checkedB"
                                                    color="primary"
                                                    size="small"
                                                    classes={{ colorPrimary: 'filter-checkbox' }}
                                                />
                                            }
                                            label={brand.name}
                                            classes={{
                                                label: "filter-label"
                                            }}
                                            style={{ display: 'block', marginTop: -8, marginBottom: -8, }}
                                        />
                                        // <RadioGroup key={index} value={brandValue} onChange={(e) => {setBrandValue(e.target.value);setSelectedBrand(brand.id);}} aria-label="brand" name="customized-radios">
                                        //     <FormControlLabel color="default" value={brand.name} control={<Radio />} label={brand.name} />
                                        // </RadioGroup>
                                    )
                                }
                            </div>
                        </div>
                    }
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                            <span style={{ fontSize: 12, fontWeight: 'bold', paddingTop: 4 }}>Price Range</span>
                            {/* <Link to="#" onClick={() => { setPriceRange(ArrayFnc.clone([min, max])) }}><span style={{ fontSize: 12 }}>Reset</span></Link> */}
                        </div>
                        {/* <span style={{ fontSize: 12, fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>{priceRange[0]} MMK - {priceRange[1]} MMK</span>
                        <div style={{ padding: '0 15px' }}>
                            <Slider
                                value={priceRange}
                                onChange={(event, newValue) => setPriceRange(newValue)}
                                min={min}
                                max={max}
                                step={5000}
                                style={{ color: 'black' }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ color: '#757575', fontSize: 12 }}>{min} MMK</span>
                            <span style={{ color: '#757575', fontSize: 12 }}>{max} MMK</span>
                        </div> */}
                        <RadioGroup aria-label="price" name="price1" value={price} onChange={(e) => setPrice(e.target.value)}>
                            <FormControlLabel value="" control={<Radio />} label="All" />
                            <FormControlLabel value="0-10000" control={<Radio />} label="0 MMK - 10000 MMK" />
                            <FormControlLabel value="0-20000" control={<Radio />} label="0 MMK - 20000 MMK" />
                            <FormControlLabel value="0-30000" control={<Radio />} label="0 MMK - 30000 MMK" />
                            <FormControlLabel value="30000-" control={<Radio />} label="30000 MMK above" />
                            {/* <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
                        </RadioGroup>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-8 col-lg-9" style={{ textAlign: "center" }}>
                    <div style={{ borderBottom: '1px solid #eee', display: 'flex', justifyContent: 'space-between', padding: '24px 0 16px' }}>
                        <span style={{ color: '#757575', fontSize: 14 }}>{total} products</span>
                        <span style={{ fontSize: 14 }}>Sort By: <b>Best Selling</b></span>
                    </div>
                    <div className="row" >
                        {
                            products.map((product, index) =>
                                <React.Fragment key={index}>
                                    <div className="col-6 col-sm-4 col-md-3" style={{ margin: '24px 0', padding: '12px 0' }}>
                                        <Product history={history} item={product} />
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </div>
                    <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
                        <Pagination count={last_page} page={page} onChange={(event, value) => { setPage(value) }} classes={{ root: 'pagination' }} />
                    </div>
                    {
                        loading &&
                        <CircularProgress color="secondary" style={{ marginTop: 150 }} />
                    }
                </div>
            </div>
        </div>
    )
}

export default withRouter(ProductList);

export const Product = props => {
    const { item, history } = props;
    return (
        <div
            className="carousel-quicklook"
            style={{
                textAlign: "center",
            }}
        >
            <div className="carouselBodyPosition" style={{ width: '100%' }}>
                <Link to={`/product/${item.id}`}>
                    <div
                        className={"my-carousel-item"}
                        style={{
                            backgroundImage: `url(${item.image})`,
                            width: 162,
                            height: 162,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            marginBottom: 12,
                            margin: "auto",
                        }}
                    >
                    </div>

                </Link>
                <div onClick={() => history.push(`/product/${item.id}`)} style={{ cursor: 'pointer' }}>
                    {/* <div
                        style={{
                            display: "flex",
                            height: 24,
                            fontWeight: 700,
                            alignItems: "center",
                            fontSize: 11,
                            color: "black",
                            justifyContent: "center",
                        }}
                    >
                        exclusive
                    </div> */}
                    <div
                        style={{
                            display: "flex",
                            height: 24,
                            fontWeight: 700,
                            alignItems: "center",
                            fontSize: 11,
                            color: "black",
                            justifyContent: "center",
                        }}
                    >
                        {item.brandName}
                    </div>
                    <div
                        className="productAbout"
                        style={{
                            display: "flex",
                            flex: 1,
                            fontSize: 12,
                            lineHeight: 1.25,
                            maxHeight: 60,
                            overflow: "hidden",
                            flexDirection: "column",
                            marginTop: 7
                        }}
                    >
                        <Typography
                            variant="caption"
                            style={{
                                fontWeight: 700,
                                lineHeight: 1,
                                textTransform: "uppercase",
                                color: "black",
                                textDecoration: 'none',
                            }}
                        >
                            {item.name}
                        </Typography>
                        <Typography
                            variant="caption"
                            style={{
                                fontWeight: 400,
                                textTransform: "lowercase",
                                color: "black",
                                textAlign: "center",
                                textDecoration: 'none',
                                marginTop: 7
                            }}
                        >
                            {item.description.length > 35
                                ? item.description.substring(0, 35) + "..."
                                : item.description}
                        </Typography>
                    </div>
                    <Typography
                        variant="caption"
                        style={{
                            fontWeight: 700,
                            textTransform: "lowercase",
                            color: "black",
                            textAlign: "center",
                        }}
                    >
                        {item.point}
                    </Typography>
                    {
                        item.original_price ?
                            <Typography
                                variant="caption"
                                style={{
                                    color: "red",
                                    textAlign: "center",
                                    textDecorationLine: 'line-through',
                                    textDecorationStyle: 'solid',
                                    paddingRight: 5
                                }}
                            >
                                {formatMoney(item.original_price)} MMK
                            </Typography>
                            :
                            <></>
                    }
                    <Typography
                        variant="caption"
                        style={{
                            fontWeight: 'bold',
                            color: "black",
                            textAlign: "center",
                        }}
                    >
                        {formatMoney(item.price)} MMK
                    </Typography>
                </div>
                {/* <div
                    style={{
                        display: "flex",
                        justifyContent: 'center',
                        marginTop: 13,
                        paddingBottom: 4,
                    }}
                >
                    <Rating />
                </div> */}
            </div>
        </div>
    )
}

export const Rating = props => {
    return (
        <div aria-label="4 stars" className="css-jp4jy6" data-comp="StarRating ">
            <div className="css-1w5zg6j">
                <span className="css-1be0bml"></span>
                <span className="css-1v1px9n"></span>
                <span className="css-1v1px9n"></span>
                <span className="css-1v1px9n"></span>
                <span className="css-1v1px9n"></span>
            </div>
            <div data-at="star_rating_style" className="css-j7llew" style={{ width: '80%' }}>
                <span className="css-1be0bml"></span>
                <span className="css-1v1px9n"></span>
                <span className="css-1v1px9n"></span>
                <span className="css-1v1px9n"></span>
                <span className="css-1v1px9n"></span>
            </div>
        </div>
    )
}