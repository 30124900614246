import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import "../App.css";
import {
  Typography,
  Divider,
  FormControl,
  NativeSelect,
  InputBase,
  Button,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { Link, withRouter } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ProductCarousel } from "../components/carousel";
import { ProductPageDialog } from "../components/subject";
import LanguageIcon from "@material-ui/icons/Language";
import Checkout from "./checkout";
import AppContext from "../components/AppContext";
import useDidUpdateEffect from "../components/hooks/useDidUpdateEffect";
import { font, formatMoney } from "../components/common";
import { getData } from "../components/fetch";

const BootstrapInput = withStyles((theme) => ({
  root: {
    height: 40,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default helvetica neue,helvetica,arial,sans-serif font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      // font.primary,
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

function BasketPage(props) {
  const { history } = props;
  const { cartCount, cartItems } = useContext(AppContext);
  const [zipcode, setZipcode] = React.useState("");
  const [btnSeriesNum, setBtnSeriesNum] = useState(4);
  const [outOfStockItem_ids, setOutOfStockItem_ids] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  useLayoutEffect(() => {
    setBtnSeriesNum(
      window.innerWidth <= 450 && window.innerHeight >= 200
        ? 2
        : window.innerWidth == 760
          ? 3
          : 4
    );
  }, [window.innerWidth]);

  useEffect(() => {
    var total = 0;
    if (cartItems) {
      cartItems.map(item => {
        total += item.cart_item.price * item.qty;
      });
    }
    setTotalPrice(total);
    setOutOfStockItem_ids([])
  }, [cartItems])

  return (
    <div className="basket">
      <div
        className="container"
        style={{
          marginTop: 25,
          marginBottom: 25,
          maxWidth: 1024,
        }}
      >
        <div
          className="row"
          style={{ marginRight: -9, marginLeft: -9, justifyContent: "center" }}
        >
          <div
            className="col-lg-8 col-sm-12"
          >
            <div
              style={{
                webkitBoxAlign: "center",
                alignItems: "center",
                marginBottom: 12,
              }}
            >
              <h1
                style={{
                  overflowWrap: "break-word",
                  fontFamily: "georgia, times, serif",
                  fontSize: 24,
                  lineHeight: 1,
                }}
              >
                My Basket
              </h1>
            </div>

            {/* <Divider className="basketDividerLine" /> */}

            {/* <div
              className="container"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div
                className="row"
                style={{
                  display: "flex",
                  marginLeft: -4,
                  marginRight: -4,
                  flexFlow: "row wrap",
                  justifyContent: "center",
                }}
              >
                <div
                  className="col-6"
                  style={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    display: "block",
                    minWidth: 0,
                    width: "100%",
                    flex: 1,
                    minWidth: 300,
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      width: "100%",
                      position: "relative",
                      cursor: "pointer",
                      borderWidth: 2,
                      borderColor: "rgb(238, 238, 238)",
                      borderStyle: "solid",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        webkitBoxPack: "center",
                        justifyContent: "center",
                        paddingLeft: 12,
                        paddingRight: 12,
                        height: 65,
                        textAlign: "center",
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>
                        Sign in to see your Beauty Insider points & redeem your
                        rewards
                      </Typography>
                    </div>
                  </div>
                </div>

                <div
                  className="col-6"
                  style={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    display: "block",
                    minWidth: 0,
                    width: "100%",
                    flex: 1,
                    minWidth: 300,
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      width: "100%",
                      position: "relative",
                      cursor: "pointer",
                      borderWidth: 2,
                      borderColor: "rgb(238, 238, 238)",
                      borderStyle: "solid",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        webkitBoxPack: "center",
                        justifyContent: "center",
                        paddingLeft: 12,
                        paddingRight: 12,
                        height: 65,
                        textAlign: "center",
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>
                        Sign in to see your Beauty Insider points & redeem your
                        rewards
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              style={{
                display: "block",
                backgroundColor: "rgb(244, 244, 244)",
                marginBottom: 24,
                marginTop: 12,
                padding: 12,
              }}
            >
              <div style={{ display: "block" }}>
                <div
                  style={{
                    display: "block",
                    marginBottom: 12,
                    lineHeight: 1.25,
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 700,
                      overflowWrap: "break-word",
                      fontSize: 16,
                    }}
                  >
                    Items in basket ({cartCount})
                  </Typography>
                </div>
                <div
                  className="basketItem container"
                  style={{
                    display: "block",
                    backgroundColor: "rgb(255, 255, 255)",
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                    paddingBottom: 16,
                  }}
                >
                  {
                    cartItems.map((cartItem, index) =>
                      <BasketItem key={index}
                        className="col-12"
                        cartItem={cartItem}
                        onClick={() => history.push(`/product/${cartItem.product_id}`)}
                        isOutOfStock={outOfStockItem_ids.includes(cartItem.id)}
                      />
                    )
                  }
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-4 col-sm-12">
            <div
              className="container"
              style={{
                marginBottom: 16,
                borderColor: "rgb(238, 238, 238)",
                padding: 16,
                borderWidth: 2,
                borderStyle: "solid",
                fontSize: 14,
              }}
            >
              <div className="row">
                <div
                  className="col-8"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  Subtotal
                </div>
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    fontWeight: 600,
                  }}
                >
                  {totalPrice} MMK
                </div>
              </div>
              {/* <div className="row">
                <Link
                  className="col-8"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    color: "inherit",
                  }}
                >
                  Shipping & Handling
                  <HelpIcon
                    style={{
                      height: 20,
                      fill: "currentcolor",
                      verticalAlign: "text-bottom",
                      color: "rgb(204, 204, 204)",
                      marginLeft: 8,
                      width: 20,
                    }}
                  />
                </Link>
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    fontWeight: 600,
                  }}
                >
                  FREE
                </div>
              </div>
              <div className="row">
                <Link
                  className="col-8"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    color: "inherit",
                  }}
                >
                  Tax
                  <HelpIcon
                    style={{
                      height: 20,
                      fill: "currentcolor",
                      verticalAlign: "text-bottom",
                      color: "rgb(204, 204, 204)",
                      marginLeft: 8,
                      width: 20,
                    }}
                  />
                </Link>
                <div
                  className="col-4"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    fontWeight: 600,
                  }}
                >
                  TBD
                </div>
              </div>
               */}
              <Divider
                style={{
                  display: "flex",
                  color: "rgba(0, 0, 0, 0.063)",
                  marginTop: 12,
                  marginBottom: 12,
                  justifyContent: 'space-between'
                }}
              />
              <div className="row">
                <div
                  className="col-8"
                  style={{
                    display: "flex",
                    fontSize: 16,
                    fontWeight: 600,
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  Total
                </div>
                <div
                  className="col-6"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {totalPrice} MMK
                </div>
              </div>
              {/* <div
                className="column"
                style={{
                  display: "block",
                  marginTop: 8,
                  marginBottom: 8,
                  fontSize: 12,
                  lineHeight: 1.25,
                }}
              >
                <div
                  className="col-12"
                  style={{
                    padding: "0px 0px",
                    display: "inline",
                    marginRight: "0.5em",
                  }}
                >
                  Pay in 4 interest-free payments of $93.50
                  <ProductPageDialog />
                </div>
                <Typography
                  style={{
                    overflowWrap: "break-word",
                    color: "rgb(117, 117, 117)",
                    marginTop: 8,
                    fontSize: 12,
                  }}
                >
                  Shipping & taxes calculated during checkout
                </Typography>
              </div>
               */}
              <div
                style={{
                  display: "block",
                  minWidth: 0,
                  width: "100%",
                  flex: 1,
                  marginTop: 15,
                }}
              >

                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: 270, height: 46, marginBottom: 8 }}
                  onClick={async () => {
                    var response = await getData(`api/v1/customer/cart-items/stock-check`);
                    if (response.ok) {
                      if (cartItems.length > 0) {
                        if (response.data.length === 0) {
                          history.push("/checkout");
                        } else {
                          alert('Some items are out of stock. Please check.')
                          setOutOfStockItem_ids(response.data)
                        }
                      } else {
                        alert('Please check items.')
                        setOutOfStockItem_ids(response.data)
                      }
                    }
                  }}
                >
                  CheckOut
                </Button>
                {/* <Button
                  variant="outlined"
                  style={{
                    width: 270,
                    height: 46,
                    borderColor: "#000",
                  }}
                >
                  <Typography variant="p">Pay With</Typography>
                </Button> */}
              </div>
            </div>
            {/* <div className="container">
              <div
                className="col-lg-12 col-sm-12"
                style={{ marginBottom: 4 }}
              >
                <Link
                  style={{
                    fontSize: 12,
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    color: "inherit",
                  }}
                >
                  View promo codes
                </Link>
              </div>
              <div
                className="col-lg-12 col-sm-12"
                style={{
                  display: "flex",
                  borderColor: "#ccc",
                  width: "100%",
                  height: "48px",
                  border: "1px solid ",
                  borderRadius: 5,
                  padding: 10,
                }}
              >
                <InputBase
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    fontSize: 14,
                    fontFamily: "inherit",
                    padding: 0,
                  }}
                  placeholder="Promo Or Reward Code"
                  inputProps={{
                    "aria-label": "Promo Or Reward Code",
                  }}
                  value={zipcode}
                  onChange={(e) => {
                    if (e.target.value.length <= 4) {
                      setZipcode(e.target.value);
                    }
                  }}
                />
              </div>
            </div> */}
            {/* <Divider
              style={{
                display: "flex",
                color: "rgb(238, 238, 238)",
                marginTop: 24,
                marginBottom: 24,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
              }}
            /> */}

            {/* <div className="row" style={{ marginBottom: 22 }}>
              <div className="col-6">
                <LanguageIcon
                  style={{
                    display: "inline-block",

                    marginRight: "6px",

                    fontSize: "1.25em",
                  }}
                />
                <Link
                  style={{
                    fontSize: 12,
                    display: "inline-block",
                    flex: 1,
                    justifyContent: "flex-end",
                    color: "inherit",
                  }}
                >
                  View promo codes
                </Link>
              </div>
              <div className="col-6">
                <LanguageIcon
                  style={{
                    display: "inline-block",

                    marginRight: "6px",

                    fontSize: "1.25em",
                  }}
                />
                <Link
                  style={{
                    fontSize: 12,
                    display: "inline-block",
                    flex: 1,
                    justifyContent: "flex-end",
                    color: "inherit",
                  }}
                >
                  View promo codes
                </Link>
              </div>
            </div>
            <div
              className="container"
              style={{
                marginBottom: 16,
                borderColor: "rgb(238, 238, 238)",
                padding: 16,
                borderWidth: 2,
                borderStyle: "solid",
                fontSize: 14,
              }}
            >
              <div className="column">
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    fontSize: 16,
                    fontWeight: 600,
                    padding: 0,
                    marginBottom: 10,
                  }}
                >
                  Need assistance?
                </div>
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    padding: 0,
                  }}
                >
                  1-877-Beautytips (1-877-737-4672)
                </div>
                <Link
                  className="col-12"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    color: "inherit",
                    padding: 0,
                  }}
                >
                  TTY: 1-888-866-9845
                </Link>
                <Link
                  className="col-12"
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    color: "inherit",
                    padding: 0,
                    marginTop: 8,
                    marginBottom: 8,
                    outline: 0,
                  }}
                >
                  Free return shipping or return in store
                  <HelpIcon
                    style={{
                      height: 20,
                      fill: "currentcolor",
                      verticalAlign: "text-bottom",
                      color: "rgb(204, 204, 204)",
                      marginLeft: 8,
                      width: 20,
                    }}
                  />
                </Link>
                <Typography
                  variant="p"
                  className="col-12"
                  style={{
                    overflowWrap: 'break-word',
                    padding: 0
                  }}
                >
                  We accept
                </Typography>
                <div className="col-12"
                  style={{
                    padding: 0,
                    backgroundImage: `url(${require("../assets/images/pay1.jpg")})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    width: 30,
                    height: 20,
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                </div>
              </div>
            </div> */}
          </div>

        </div>
      </div>
    </div>
  );
}

// Item in basket
const useStyles = makeStyles((theme) => ({
  margin: {
    width: 54,
  },
}));

export function BasketItem(props) {
  const classes = useStyles();
  const { cartItem, onClick, isOutOfStock } = props;
  const [stock, setStock] = useState(0);
  const { UpdateBasketItem, RemoveBasketItem } = useContext(AppContext);
  const [qty, setQty] = useState(cartItem.qty);
  useDidUpdateEffect(() => {
    UpdateBasketItem(cartItem.id, qty);
  }, [qty])
  const GetStock = async (id, variation_id, size_name) => {
    var response = await getData(`api/v1/products/${id}`);
    if (response.ok) {
      const variation = response.data.data.variations.find(x => x.id === variation_id);
      const stock = variation ? variation.sizes.length > 0 ? variation.sizes.find(x => x.name === size_name).stock : variation.stock : cartItem.cart_item.variation.total_stock
      setStock(stock);
    }
  }
  useEffect(() => {
    if (cartItem) {
      GetStock(cartItem.product_id, cartItem.cart_item.variation_id, cartItem.size_name)
    }
  }, [cartItem])
  return (
    <>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          marginLeft: -12,
          marginRight: -12,
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            paddingLeft: 12,
            paddingRight: 15,
          }}
        >
          <img src={cartItem.image} style={{ width: 97, height: 97, objectFit: 'contain', objectPosition: 'center' }} />
        </div>
        <div
          className="row col-12 "
          style={{
            display: "flex",
            flex: 5,
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <div
            className="col-lg-7 col-md-8 col-sm-9"
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              flexBasis: "0%",
              flex: 1,
              display: "block",
            }}
          >
            <div style={{ display: "block" }}>
              <Link
                style={{
                  display: "block",
                  cursor: "pointer",
                  outline: 0,
                  color: "inherit",
                }}
                to={`/product/${cartItem.product_id}`}
              >
                <div
                  style={{
                    display: "block",
                    lineHeight: 1.25,
                    fontSize: 14,
                    fontFamily: font.primary,
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      fontWeight: 700,
                      textTransform: "uppercase",
                    }}
                  >
                    {cartItem.brand.name}
                  </div>
                  <div
                    style={{
                      display: "block",
                    }}
                  >
                    {cartItem.product_name}
                  </div>
                </div>
              </Link>
            </div>

            <div
              style={{
                display: "block",
                marginTop: 4,
                fontSize: 12,
                lineHeight: 1.25,
              }}
            >
              <div
                style={{
                  display: "block",
                  color: "rgb(117, 117, 117)",
                }}
              >
                {cartItem.sku}
              </div>
              <div
                style={{
                  display: "block",
                  overflowWrap: "break-word",
                  marginTop: 4,
                  lineHeight: 1.25,
                }}
              >
                {
                  cartItem.variation_name ? `COLOR : ${cartItem.variation_name}` : ''
                }
              </div>
              <div
                style={{
                  display: "block",
                  overflowWrap: "break-word",
                  marginTop: 4,
                  lineHeight: 1,
                }}
              >
                {
                  cartItem.size_name ? `SIZE : ${cartItem.size_name}` : ''
                }
              </div>
            </div>
          </div>

          <div
            className="row col-lg-5 col-md-4 col-sm-3"
            style={{
              paddingLeft: 15,
              paddingRight: 0,
            }}
          >
            <div
              className="col-12"
              style={{
                paddingRight: 0,
                paddingLeft: 0,
                marginLeft: -4,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    display: "block",
                  }}
                >
                  <FormControl className={classes.margin}>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={qty}
                      onChange={e => setQty(e.target.value)}
                      input={<BootstrapInput />}
                    >
                      {
                        Array(10).fill(null).map((_, index) =>
                          <option value={index + 1}>{index + 1}</option>
                        )
                      }
                      {
                        qty > 10 && <option value={qty}>{qty}</option>
                      }
                    </NativeSelect>
                  </FormControl>
                </div>

                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {formatMoney(cartItem.cart_item.price * qty)} MMK
                </div>
              </div>
              {
                isOutOfStock ? <p className="out-of-stock-p">Out of Stock <br />(Remaining: {stock})</p> : <></>
              }
            </div>
            <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: 700,
                  textAlign: "right",
                  textTransform: "uppercase",
                  color: "rgb(117, 117, 117)",
                  fontSize: 11,
                  lineHeight: 1.25,
                  marginTop: 40,
                }}
              >
                {/* <Link style={{ color: "inherit" }}>
                  <div
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                      outline: 0,
                    }}
                  >
                    Move to Loves
                  </div>
                </Link> */}
                {/* <div
                  style={{
                    overflowWrap: "break-word",
                    color: "rgb(204, 204, 204)",
                    marginLeft: 8,
                    marginRight: 8,
                    border: "1px solid",
                  }}
                ></div> */}

                <div
                  style={{
                    display: "inline-block",
                    cursor: "pointer",
                    outline: 0,
                  }}
                  onClick={() => RemoveBasketItem(cartItem.id)}
                >
                  Remove
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "block",
          color: "rgba(0, 0, 0, 0.063)",
          marginTop: 16,
          marginBottom: 16,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      ></div>
    </>
  );
}

export default withRouter(BasketPage);