import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { SideBySideMagnifier } from "react-image-magnifiers";

const RNZoomSlider = (props) => {
    const { data, style, isStickyTop } = props;
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    return (
        <div style={style} className={isStickyTop ? 'sticky-top' : ''}>
            <Slider asNavFor={nav2} ref={slider1 => setNav1(slider1)}>
                {
                    data.map((item, index) =>
                        <SideBySideMagnifier
                            key={index}
                            imageSrc={item.image}
                            imageAlt={item.name || ''}
                            // largeImageSrc={item.image}
                            alwaysInPlace={true}
                        />
                    )
                }
            </Slider>
            <div style={{ padding: '0 20px' }}>
                <Slider
                    asNavFor={nav1}
                    ref={slider2 => setNav2(slider2)}
                    slidesToShow={data.length >= 3 ? 3 : data.length}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    prevArrow={<img src={require('../assets/icons/blackarrowleft.svg')} />}
                    nextArrow={<img src={require('../assets/icons/blackarrowright.svg')} />}
                >
                    {
                        data.map((item, index) =>
                            <div key={index}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={item.image} style={{ width: 60, height: 60, borderRadius: 5 }} />
                                </div>
                            </div>
                        )
                    }
                </Slider>
            </div>
        </div>
    );
};

export const SimpleSlider = props => {
    const { data, slidesToShow, swipeToSlide, swipe, prevArrow, nextArrow, sliderProps, component } = props;
    return (
        <Slider
            slidesToShow={data.length >= slidesToShow ? slidesToShow : data.length}
            slidesToScroll={slidesToShow}
            focusOnSelect={true}
            infinite={false}
            prevArrow={prevArrow || <img src={require('../assets/icons/blackarrowleft.svg')} />}
            nextArrow={nextArrow || <img src={require('../assets/icons/blackarrowright.svg')} />}
            {...sliderProps}
        >
            {
                data.map((item, index) =>
                    <div key={index}>
                        {component(item)}
                    </div>
                )
            }
        </Slider>
    )
}

export default RNZoomSlider;