import React from "react";
import {
  Container,
  Typography,
  Link,
  Grid,
  InputBase,
  Button,
  FormControl,
  NativeSelect,
} from "@material-ui/core";
import ArrowRightOutlinedIcon from "@material-ui/icons/ArrowRightOutlined";
import Divider from "@material-ui/core/Divider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export default function Footer() {
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div
      style={{
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(255, 255, 255)",
        marginTop: 48,
        paddingBottom: 48,
        paddingTop: 48,
      }}
    >
      <div
        className="container-md"
        style={{
          paddingLeft: 22,
          paddingRight: 22,
        }}
      >
        {/* <div
          className="row"
          style={{
            marginLeft: -12,
            marginRight: -12,
          }}
        >
          <FooterLists
            name="About Beautytips"
            lists={[
              {
                title: "Google",
                link: "https://www.google.com/",
              },
              {
                title: "Youtube",
                link: "https://www.youtube.com/",
              },
              {
                title: "Facebook",
                link: "https://www.facebook.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "Google",
                link: "https://www.google.com/",
              },
              {
                title: "Youtube",
                link: "https://www.youtube.com/",
              },
              {
                title: "Youtube",
                link: "https://www.youtube.com/",
              },
            ]}
          />
          <FooterLists
            name="My Beautytips"
            lists={[
              {
                title: "Google",
                link: "https://www.google.com/",
              },
              {
                title: "Youtube",
                link: "https://www.youtube.com/",
              },
              {
                title: "Facebook",
                link: "https://www.facebook.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "Youtube",
                link: "https://www.youtube.com/",
              },
            ]}
          />
          <FooterLists
            name="Help & FAQs"
            lists={[
              {
                title: "Google",
                link: "https://www.google.com/",
              },
              {
                title: "Youtube",
                link: "https://www.youtube.com/",
              },
              {
                title: "Facebook",
                link: "https://www.facebook.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
            ]}
          />
          <FooterLists
            name="Ways to Shop"
            lists={[
              {
                title: "Google",
                link: "https://www.google.com/",
              },
              {
                title: "Youtube",
                link: "https://www.youtube.com/",
              },
              {
                title: "Facebook",
                link: "https://www.facebook.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
              {
                title: "About Beautytips",
                link: "https://www.google.com/",
              },
            ]}
          />
        </div> */}

        {/* <Divider
          variant="fullWidth"
          style={{
            color: "rgb(102, 102, 102)",
            marginTop: 24,
            marginBottom: 24,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
          }}
        /> */}

        {/* <div className="container-md">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="row">
                <div
                  style={{
                    display: 'flex',
                    height: 40,
                    webkitBoxAlign: "center",
                    alignItems: "center",
                    minWidth: 0,
                    marginRight: 12,
                    fontSize: 12,
                  }}
                >Sign up for Beautytips Emails</div>
                
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">mhk</div>
            <div className="col-lg-3 col-md-3 col-sm-12">mhk</div>
          </div>
        </div> */}

        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(150, 150, 150)",
            marginTop: 48,
            fontSize: 11,
          }}
        >
          <div style={{ display: "flex", flex: 1, marginBottom: "1rem" }}>
            <Typography style={{ fontSize: 11 }}>
              Copyright © 2021 Beautytips. All rights reserved. Terms of
              Use | Privacy Policy
            </Typography>
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <Typography style={{ fontSize: 11 }}>
              <Link color="inherit" href="#">
                No.221, Ground Floor, Bo Myat Tun Street, Botahtaung Township, Yangon, Myanmar{" "}
              </Link>
              |
              <Link color="inherit" href="#">
                09978707360
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export function FooterLists(props) {
  return (
    <div className="col-sm-12 col-md-6 col-lg-3">
      <div
        style={{
          paddingLeft: 12,
          paddingRight: 12,
        }}
      >
        <Typography
          variant="caption"
          component="div"
          style={{
            fontWeight: 700,
            marginBottom: 8,
            fontSize: 14,
          }}
        >
          {props.name}
        </Typography>

        <div style={{ paddingBottom: 12 }}>
          <div>
            {props.lists.map((item, i) => (
              <Typography
                variant="caption"
                component="div"
                style={{
                  display: "flex",
                  flex: 3,
                  alignItems: "center",
                  fontSize: 12,
                  height: 26,
                }}
              >
                <Link href={item.link} color="inherit">
                  {item.title}
                </Link>
              </Typography>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ccc",
    height: 40,
    borderRadius: "5px 0px 0px 5px",
  },
  input: {
    marginLeft: theme.spacing(1.5),
    flex: 1,
  },
  iconButton: {
    padding: 8,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));
