import React from 'react'
import { withRouter } from 'react-router-dom'

const Location = ({ history }) => {
    return (
        <div className="container-md">
            <div className="row">
                <div className="col-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d955.0073049911932!2d96.16958072922652!3d16.775221799278018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTbCsDQ2JzMwLjgiTiA5NsKwMTAnMTIuNSJF!5e0!3m2!1sen!2smm!4v1608046773249!5m2!1sen!2smm" width="100%" height="450" frameborder="0" style={{ marginTop: 50, border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Location)
