import { useState, useEffect, useCallback } from 'react';
import { API_URL, isFile, isObject } from '../components/common';
import { getToken } from './storage';
import debounce from "debounce-promise";

export default function useFetch(refreshValue = [], url = '', data = {}, method = 'GET', useAPI_URL = true, isDebounce = true) {
    const [response, setResponse] = useState(null);
    const fetchData = async (url, data) => {
        var token = await getToken();
        var options = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + token,
                'cache-control': 'no-cache'
            },
        };
        if (method.toUpperCase() !== 'GET') {
            options = { ...options, body: JSON.stringify(data) }
        }
        try {
            const res = await fetch((useAPI_URL ? API_URL : '') + url, options);
            if (res.ok) {
                var res_data = [];

                if (res.status !== 204) {
                    var res_data = await res.json();
                }
                setResponse({
                    ok: res.ok,
                    status: res.status,
                    data: res_data,
                });
            } else {
                setResponse({
                    ok: res.ok,
                    status: res.status,
                    data: await res.text(),
                });
            }
        } catch (error) {
            // console.log(error)
            setResponse({
                ok: false,
                status: error.status,
                data: error,
            });
        }
    };

    const debounceFetchData = useCallback(debounce(fetchData, 500, { leading: true }), [])

    useEffect(() => {
        if (isDebounce) {
            debounceFetchData(url, data, method, useAPI_URL);
        } else {
            fetchData(url, data, method, useAPI_URL);
        }
    }, refreshValue);
    return response;
};

export async function getData(url = '', useAPI_URL = true) {
    var token = await getToken();
    return await fetch((useAPI_URL ? API_URL : '') + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token,
            'cache-control': 'no-cache'
        }
    }).then(async (response) => {
        // console.log("rew",response)
        if (response.ok) {
            // you can call response.json() here too if you want to return json
            var data = [];

            if (response.status !== 204) {
                var promise = response.json();
                // console.log("promise1",promise)
                await promise.then(x => data = x);
            }
            return {
                ok: response.ok,
                status: response.status,
                data: data,
            }
        } else {
            var promise = await response.json();
            //handle errors in the way you want to
            var message = '';
            switch (response.status) {
                case 401:
                    message = "Unauthorized"
                    break;
                case 404:
                    message = 'Object not found';
                    break;
                case 500:
                    message = 'Internal server error';
                    break;
                default:
                    message = 'Some error occured';
                    break;
            }
            return {
                ok: response.ok,
                status: response.status,
                data: message,
            }
        }
    }).then(json => json);
}

export async function postData(url = '', data = {}) {
    return await fetchData(url, data);
}

export async function putData(url = '', data = {}) {
    return await fetchData(url, data, 'PUT');
}

export async function deleteData(url = '', data = {}) {
    return await fetchData(url, data, 'DELETE');
}

export async function postFormData(url = '', data = {}, method = 'POST') {
    var token = await getToken();

    return await fetch(API_URL + url, {
        method: method,
        headers: {
            'authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            // 'Content-Type': 'multipart/form-data',
            'Process-data': false
        },
        body: toFormData(data)
    }).then(async (response) => {
        // console.log("formData",response)
        if (response.ok) {
            // you can call response.json() here too if you want to return json
            var data = [];
            if (response.status !== 204) {
                var promise = response.json();
                await promise.then(x => data = x);
            }
            return {
                ok: response.ok,
                status: response.status,
                data: data,
            }
        } else {
            var message = await response.json();
            return {
                ok: response.ok,
                status: response.status,
                data: message,
            }
        }
    }).then(json => json);
}

export async function postWaveData(url = '', data = {}) {
    return await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(async (response) => {
        if (response.ok) {
            // you can call response.json() here too if you want to return json
            var data = [];
            if (response.status !== 204) {
                var promise = response.json();
                await promise.then(x => data = x);
            }
            return {
                ok: response.ok,
                status: response.status,
                data: data,
            }
        } else {
            var promise = await response.json();

            //handle errors in the way you want to
            var message = '';
            switch (response.status) {
                case 401:
                    message = "Access Denied"
                    break;
                case 403:
                    message = "Forbidden Access"
                    break;
                case 404:
                    message = 'Object not found';
                    break;
                case 500:
                    message = 'Internal server error';
                    break;
                default:
                    message = 'Some error occured';
                    break;
            }
            return {
                ok: response.ok,
                status: response.status,
                data: promise,
            }
        }
    }).then(json => json);
}

export async function fetchData(url = '', data = {}, method = 'POST', useAPI_URL = true) {
    var token = await getToken();
    return await fetch((useAPI_URL ? API_URL : '') + url, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + token,
            'cache-control': 'no-cache'
        },
        body: JSON.stringify(data),
    }).then(async (response) => {
        // console.log("fetch",response)
        if (response.ok) {
            // you can call response.json() here too if you want to return json
            var data = [];
            if (response.status !== 204) {
                var promise = response.json();
                await promise.then(x => data = x);
            }
            return {
                ok: response.ok,
                status: response.status,
                data: data,
            }
        } else {
            var promise = await response.json();
            // console.log("promise",promise,url);
            //handle errors in the way you want to
            var message = '';
            switch (response.status) {
                case 401:
                    message = "Access Denied"
                    break;
                case 403:
                    message = "Forbidden Access"
                    break;
                case 404:
                    message = 'Object not found';
                    break;
                case 500:
                    message = 'Internal server error';
                    break;
                default:
                    message = 'Some error occured';
                    break;
            }
            return {
                ok: response.ok,
                status: response.status,
                data: promise,
            }
        }
    }).then(json => json);
}

var populateItemToFormData = (form_data, value, key) => {
    if (Array.isArray(value)) {
        for (var i = 0; i < value.length; i++) {
            var x = value[i];
            form_data = populateItemToFormData(form_data, x, `${key}[${i}]`);
        }
    } else if (isObject(value) && !isFile(value)) {
        for (var k in value) {
            var v = value[k];
            form_data = populateItemToFormData(form_data, v, `${key}[${k}]`);
        }
    } else {
        form_data.append(`${key}`, value);
    }
    return form_data;
};

export const toFormData = (item) => {
    var form_data = new FormData();
    for (var key in item) {
        var value = item[key];
        form_data = populateItemToFormData(form_data, value, key);
    }
    return form_data;
}

export const toUrlencodedFormData = (items) => {
    var formBody = [];
    for (var property in items) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(items[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return formBody
}
