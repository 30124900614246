import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react'

export const SimpleDialog = ({ title, children, open, onClose, dialogProps, dialogTitleProps, dialogContentProps }) => {
    return (
        <Dialog onClose={onClose}
            classes={{
                container: 'addedToBasketDialogContainer',
                paper: 'addedToBasketDialogPaper',
            }}
            maxWidth="sm"
            aria-labelledby="customized-dialog-title"
            open={open}
            {...dialogProps}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={onClose}
                classes={{ root: 'addedToBasketDialogTitle' }}
                {...dialogTitleProps}
            >{title}</DialogTitle>
            <DialogContent {...dialogContentProps}>
                {children}
            </DialogContent>
        </Dialog>
    )
}