import React from 'react'
import { formatMoney } from './common';

export const CartItem = (props) => {
    const { cartItem, history } = props;
    return (
        <div className="cart-item-nav-container" onClick={() => history.push(`/product/${cartItem.product_id}`)}>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <img src={cartItem.image} style={{ width: 62, height: 62, objectFit: 'contain', objectPosition: 'center' }} />
                </div>
                <div style={{ flex: 3, display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                    <strong className="small">{cartItem.brand.name}</strong>
                    <span className="small">{cartItem.product_name}</span>
                        {
                            cartItem.variation_name ?
                                <span className="small">
                                    COLOR: {cartItem.variation_name}
                                    <span className="small" style={{ color: 'rgb(117, 117, 117)', marginLeft: '0.25em' }}>(x{cartItem.qty})</span>
                                </span>:<></>
                        }
                        {
                            cartItem.size_name ?
                                <span className="small">
                                    SIZE: {cartItem.size_name}
                                </span>
                                :
                                <></>
                        }
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <strong className="small" style={{ textAlign: 'right' }}>{formatMoney(cartItem.cart_item.price * cartItem.qty)}</strong>
                    <strong style={{ textAlign: 'right', fontSize: 10 }}>MMK</strong>
                </div>
            </div>
        </div>
    )
}
