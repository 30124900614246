import React, { useReducer } from 'react'

const useStateReducer = (initialState) => {
    return useReducer(
        (prevState, action) => ({
            ...prevState,
            [action.key]: action.value
        }),
        initialState
    );
}

export default useStateReducer;