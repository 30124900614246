import React, { useState, useEffect, useContext } from "react";
import "../App";
import SearchIcon from "@material-ui/icons/Search";
import { NavDropdown, ProfileDropdown } from "./navDropdown";
import { useMobileView } from "./hooks/useMobileView";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import Button from "@material-ui/core/Button";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import CreateAccountDialog, { CreateAccountButton } from "./registerDialog";
import SignInDialog, { SignInButton } from "./signInDialog";
import NavCategory from './navCategory'
import { Link, useLocation, withRouter } from "react-router-dom";
import AppContext from "./AppContext";
import { formatMoney } from "./common";
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@material-ui/core";
import { removeToken } from "./storage";
import { CartItem } from "./cart";

const MainNavbar = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname !== "/products") {
        setSearch('')
      }
    });

    return () => unlisten();
  }, [])

  const [openHeart, setOpenHeart] = useState(false);
  const [openBasket, setOpenBasket] = useState(false);
  const [search, setSearch] = useState('');
  const { token, setToken, openSignIn, userName, userImage, setOpenSignIn, openRegister, setOpenRegister, cartCount, cartItems, cartTotal, openAddedToBasket, setOpenAddedToBasket, recentlyAddedCartItem } = useContext(AppContext);

  const handleCloseAddedToBasket = () => setOpenAddedToBasket(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const Search = async (e) => {
    e.preventDefault();
    history.push({ pathname: '/products', state: { search } })
  }
  return (
    <>
      <div className="container" style={{ padding: "6px 15px" }}>
        <div className="row" style={{ justifyContent: "center" }}>
          <div
            className="col-6"
            style={{ minWidth: isMobileView ? window.innerWidth : 320 }}
          >
            <div className="row" style={{ alignItems: "center" }}>
              <Link to="/" className="col-2" style={{ padding: 0 }}>
                <img
                  src={require("../assets/images/BeautytipsLogo.jpg")}
                  style={{ maxWidth: "100%" }}
                />
              </Link>
              <div className="col-10">
                <form onSubmit={Search}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className={"form-control"}
                    style={{ borderRadius: 20, paddingLeft: 35 }}
                  />
                  <SearchIcon className="textInputIcon" />
                </form>
              </div>
            </div>
          </div>
          <div
            className="col-6"
            style={{ minWidth: isMobileView ? window.innerWidth : 320 }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                height: "100%",
                paddingBottom: 6,
              }}
            >
              <div
                className="store-Flex"
                style={{
                  flex: 5,
                  display: "flex",
                  justifyContent: "center",
                  height: 62,
                }}
              >
                <NavDropdown
                  icon={require("../assets/images/location-black.png")}
                  icons={require("../assets/images/location.png")}
                  style={{ width: 25 }}
                  header={"Find Beautytips"}
                  onClick={() => history.push('/location')}
                >
                </NavDropdown>
              </div>
              <div
                className="community-Logo"
                style={{
                  flex: 4,
                  display: "flex",
                  justifyContent: "center",
                  height: 62,
                }}
              >
                <NavDropdown
                  icon={require("../assets/images/blackcommunity.svg")}
                  icons={require("../assets/images/community.svg")}
                  header={"Social Platforms"}
                >
                  <div
                    style={{
                      paddingTop: 16,
                      paddingBottom: 16,
                      borderRadius: 6,
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#fff",
                      color: "#000",
                      boxShadow: "0 0 6px 0 rgba(0,0,0,.2)",
                      left: isMobileView ? -15 : -90,
                      top: -29,
                    }}
                  >
                    <div
                      style={{
                        lineHeight: 1.25,
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div>
                      <ProfileDropdown
                        style={{ padding: "12px 16px" }}
                        imaged={require("../assets/images/facebook.png")}
                        eventsHeader={"Go to facebook page"}
                        About={"See more about us in facebook!"}
                        onClick={() => window.open(
                          'https://www.facebook.com/beautytipscosmeticsgallery',
                          '_blank' // <- This is what makes it open in a new window.
                        )}
                      />
                      <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div>
                      <ProfileDropdown
                        style={{ padding: "12px 16px" }}
                        imaged={require("../assets/images/telegram.svg")}
                        eventsHeader={"Go to telegram"}
                        About={"Open in telegram!"}
                        onClick={() => window.open(
                          'https://t.me/beautytipscosmeticsgallery',
                          '_blank' // <- This is what makes it open in a new window.
                        )}
                      />
                      <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div>
                      <ProfileDropdown
                        style={{ padding: "12px 16px" }}
                        imaged={require("../assets/images/viber.svg")}
                        eventsHeader={"Go to viber"}
                        About={"Contact us in viber!"}
                        onClick={() => window.open(
                          'https://invite.viber.com/?g2=AQAwSJSFkfzpBE6epckvKsRNlp%2BhjmRuE0PUlRReKqMpKyTI%2F9Volvd4%2FqexNZdr',
                          '_blank' // <- This is what makes it open in a new window.
                        )}
                      />
                      <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div>
                      {/* <ProfileDropdown
                        style={{ padding: "12px 16px" }}
                        imaged={require("../assets/images/facebook.png")}
                        eventsHeader={"Go to Beautytip facebook page"}
                        About={"See more about us in facebook!"}
                        onClick={() => window.open(
                          'https://www.facebook.com/beautytipsos',
                          '_blank' // <- This is what makes it open in a new window.
                        )}
                      /> */}
                      {/* <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div> */}
                      {/* <ProfileDropdown
                        style={{ padding: "12px 16px" }}
                        imaged={require("../assets/images/ball.jpg")}
                        eventsHeader={"Store Events"}
                        About={"Exciting launches, parties, & more!"}
                      />
                      <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div>
                      <ProfileDropdown
                        style={{ padding: "12px 16px" }}
                        imaged={require("../assets/images/ball.jpg")}
                        eventsHeader={"Store Events"}
                        About={"Exciting launches, parties, & more!"}
                      />

                      <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div> */}
                    </div>
                  </div>
                </NavDropdown>
              </div>
              <div
                className="signIn-Logo"
                style={{
                  flex: 4,
                  display: "flex",
                  justifyContent: "center",
                  height: 62,
                }}
              >
                <NavDropdown
                  icon={userImage ? userImage : require("../assets/images/blacksignIn.svg")}
                  icons={userImage ? userImage : require("../assets/images/me32.svg")}
                  header={token ? userName : "Sign In"}
                  style={{
                    objectFit: "cover",
                    flexShrink: 0,
                    boxShadow:
                      "rgb(255, 255, 255) 0px 0px 0px 1px, rgb(0, 0, 0) 0px 0px 0px 2px",
                    width: 32,
                    height: 32,
                    marginTop: -4,
                    marginBottom: -4,
                    display: "inline-block",
                    maxWidth: "100%",
                    borderRadius: 99999,
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      paddingTop: token ? 0 : 16,
                      paddingBottom: 16,
                      borderRadius: 6,
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#fff",
                      color: "#000",
                      boxShadow: "0 0 6px 0 rgba(0,0,0,.2)",
                      // left: -90,
                      top: -29,
                    }}
                  >
                    {
                      token ? <>
                        <ProfileDropdown
                          style={{ padding: "12px 16px" }}
                          imaged={require("../assets/icons/track.svg")}
                          eventsHeader={"Orders"}
                          About={"View & track online or pickup orders"}
                          onClick={() => { history.push('/MyAccount/Orders') }}
                        />
                        <div
                          style={{
                            borderBottom: "1px solid",
                            borderColor: "rgba(0,0,0,0.0625)",
                          }}
                        ></div>
                        {/* <ProfileDropdown
                          style={{ padding: "12px 16px" }}
                          imaged={require("../assets/icons/heart.svg")}
                          eventsHeader={"Loves"}
                          About={"View save products"}
                        /> */}
                        {/* <div
                          style={{
                            borderBottom: "1px solid",
                            borderColor: "rgba(0,0,0,0.0625)",
                          }}
                        ></div> */}
                        <ProfileDropdown
                          style={{ padding: "12px 16px" }}
                          imaged={require("../assets/icons/account.svg")}
                          eventsHeader={"Account Settings"}
                          About={"Contact info, addresses"}
                          onClick={() => {
                            history.push('/profile');
                          }}
                        />
                        <div
                          style={{
                            borderBottom: "1px solid",
                            borderColor: "rgba(0,0,0,0.0625)",
                          }}
                        ></div>
                        <ProfileDropdown
                          style={{ padding: "12px 16px" }}
                          // imaged={require("../assets/icons/account.svg")}
                          eventsHeader={"Sign Out"}
                          // About={"Payment, contact info, addresses, password"}
                          onClick={() => {
                            setToken(null);
                            history.push('/');
                          }}
                        />
                      </> :
                        <div
                          style={{
                            lineHeight: 1.25,
                            display: "block",
                          }}
                        >
                          <div
                            style={{
                              paddingLeft: 16,
                              paddingRight: 16,
                              marginBottom: 12,
                              display: "block",
                            }}
                          >
                            <div
                              style={{
                                display: "grid",
                                gridGap: 24,
                                gridTemplateColumns: "1fr auto auto",
                                marginBottom: 12,
                                alignItems: "center",
                                height: 52,
                              }}
                            >
                              <a
                                href="#"
                                style={{
                                  outline: 0,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundImage: `url(${require("../assets/images/Register&SignImg.png")})`,
                                    borderRadius: 99999,
                                    overflow: "hidden",
                                    objectFit: "cover",
                                    flexShrink: 0,
                                    width: 52,
                                    height: 52,
                                    marginRight: 12,
                                    display: "inline-block",
                                    maxWidth: "100%",
                                    backgroundSize: "cover",
                                  }}
                                ></div>
                                <div>
                                  <div style={{ textDecorationLine: "none" }}>
                                    <span
                                      style={{
                                        whiteSpace: "pre",
                                        wordWrap: "break-word",
                                        marginRight: 4,
                                        fontWeight: 700,
                                        fontSize: 14,
                                        color: "#000",
                                      }}
                                    >
                                      Community Profile
                                    </span>
                                  </div>

                                  <span
                                    style={{
                                      whiteSpace: "pre",
                                      wordWrap: "break-word",
                                      color: "#757575",
                                      fontSize: 12,
                                    }}
                                  >
                                    Sign in to see your profile
                                  </span>
                                </div>
                              </a>
                              {/* <div>
                                <NotificationsActiveOutlinedIcon />
                              </div>
                              <div>
                                <MailOutlineOutlinedIcon />
                              </div> */}
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                gap: 12,
                                height: 32,
                              }}
                            >
                              <SignInButton onClick={() => setOpenSignIn(true)} />
                              <CreateAccountButton onClick={() => setOpenRegister(true)} />
                            </div>
                          </div>

                          {/* <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div>
                      <ProfileDropdown
                        style={{ padding: "12px 16px" }}
                        imaged={require("../assets/images/ball.jpg")}
                        eventsHeader={"Store Events"}
                        About={"Exciting launches, parties, & more!"}
                      />
                      <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div>
                      <ProfileDropdown
                        style={{ padding: "12px 16px" }}
                        imaged={require("../assets/images/ball.jpg")}
                        eventsHeader={"Store Events"}
                        About={"Exciting launches, parties, & more!"}
                      />
                      <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div>
                      <ProfileDropdown
                        style={{ padding: "12px 16px" }}
                        imaged={require("../assets/images/ball.jpg")}
                        eventsHeader={"Store Events"}
                        About={"Exciting launches, parties, & more!"}
                      />
                      <div
                        style={{
                          borderBottom: "1px solid",
                          borderColor: "rgba(0,0,0,0.0625)",
                        }}
                      ></div> */}
                        </div>
                    }
                  </div>
                </NavDropdown>
                <SignInDialog open={openSignIn} handleClose={() => setOpenSignIn(false)} onClickCreate={() => { setOpenSignIn(false); setOpenRegister(true); }} />
                <CreateAccountDialog open={openRegister} handleClose={() => setOpenRegister(false)} />
              </div>

              {
                token ?
                  <>
                    {/* <div
                      onMouseLeave={() => setOpenHeart(false)}
                      className="heart-Logo nav-icon"
                      style={{
                        flex: 1.5,
                        display: "flex",
                        justifyContent: "center",
                        height: 62,
                        alignItems: "center",
                      }}
                    >
                      <div onMouseOver={() => setOpenHeart(true)}>
                        {openHeart ? (
                          <img
                            src={require("../assets/images/heart (2).png")}
                            width={24}
                            height={24}
                          ></img>
                        ) : (
                            <img
                              src={require("../assets/images/heart.png")}
                              width={24}
                              height={24}
                            />
                          )}
                      </div>
                      {openHeart ? (
                        <div
                          onMouseLeave={() => setOpenHeart(false)}
                          style={{
                            width: 343,
                            height: "auto",
                            borderWidth: 1,
                            borderColor: "#eee",
                            borderRadius: 8,
                            textAlign: "left",
                            position: "absolute",
                            top: 70,
                            zIndex: 1030,
                            paddingTop: 16,
                            paddingBottom: 16,
                            backgroundColor: "#fff",
                            boxShadow: "0 0 6px 0 rgba(0,0,0,.2)",
                            right: 0,
                          }}
                        >
                          <div style={{}}></div>
                        </div>
                      ) : (
                          <></>
                        )}
                    </div> */}

                    <div
                      onMouseLeave={() => setOpenBasket(false)}
                      className="basket-Logo nav-icon"
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        height: 62,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ width: 24, height: 24 }}
                        onMouseOver={() => setOpenBasket(true)}
                        onClick={() => history.push('/basket')}
                      >
                        {openBasket ? (
                          <img
                            src={require("../assets/images/shop.png")}
                            width={24}
                            height={24}
                          />
                        ) : (
                          <img
                            src={require("../assets/images/basket.png")}
                            width={24}
                            height={24}
                          />
                        )}
                        <div className="cartCount"><span>{cartCount}</span></div>
                      </div>

                      {openBasket && location.pathname !== '/basket' ? (
                        <div
                          onMouseLeave={() => setOpenBasket(false)}
                          style={{
                            width: 343,
                            height: "auto",
                            borderWidth: 1,
                            borderColor: "#eee",
                            borderRadius: 8,
                            textAlign: "left",
                            position: "absolute",
                            top: 70,
                            zIndex: 1130,
                            paddingTop: 16,
                            paddingBottom: 16,
                            backgroundColor: "#fff",
                            boxShadow: "0 0 6px 0 rgba(0,0,0,.2)",
                            right: 0
                          }}
                        >
                          <div style={{
                            flexDirection: 'column',
                            lineHeight: 1.25,
                            maxHeight: 'inherit',
                            display: 'flex',
                          }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 16px', paddingBottom: 16, borderBottom: '1px solid rgba(0, 0, 0, 0.063)' }}>
                              <span style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontWeight: 700, textTransform: 'capitalize', fontSize: 14 }}>Basket</span>
                              <Link to="/basket" style={{ fontSize: 14 }}>View all</Link>
                            </div>
                            <div>
                              <div style={{
                                overflow: 'hidden auto',
                                maxHeight: 200
                              }}>
                                {
                                  cartItems.length > 0 ? <>
                                    {
                                      cartItems.map((cartItem, index) =>
                                        <CartItem key={index} cartItem={cartItem} history={history} />
                                      )
                                    }
                                  </> : <></>
                                }
                              </div>
                              <div className="cart-item-nav-footer" style={{ display: 'flex', flexDirection: 'column', borderTopWidth: 1 }}>
                                <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                                  <strong><p>Subtotal</p></strong><p>({cartCount} Items)</p>
                                  <strong><p>{formatMoney(cartTotal)} MMK</p></strong>
                                </div>
                                <div onClick={() => { setOpenAddedToBasket(false); history.push('/basket') }} className="button-custom button-checkout" style={{ margin: 0 }}>View Basket & Checkout</div>
                              </div>
                              {/* <div className="cart-item-nav-footer" style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                  <img src={require('../assets/icons/rewardsbazaar.svg')} />
                                </div>
                                <span style={{ flex: 9, alignSelf: 'center' }} className="small">See samples, rewards, and promos in <Link>basket</Link></span>
                              </div>
                              <div className="cart-item-nav-footer" style={{ display: 'flex', border: 'none', paddingBottom: 0 }}>
                                <div style={{ flex: 1 }}>
                                  <img src={require('../assets/icons/truck.svg')} />
                                </div>
                                <span style={{ flex: 9 }} className="small"><strong className="small">Free Shipping. </strong>You're only $16.00 away from Free Shipping</span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </> : <></>
              }
            </div>
          </div>
        </div>
      </div>
      <NavCategory />
      <Dialog onClose={handleCloseAddedToBasket}
        classes={{
          container: 'addedToBasketDialogContainer',
          paper: 'addedToBasketDialogPaper',
        }}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={openAddedToBasket}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseAddedToBasket}
          classes={{ root: 'addedToBasketDialogTitle' }}
        >Added To Basket</DialogTitle>
        <DialogContent>
          <div className="row" style={{ paddingBottom: 15 }}>
            <div className="col-sm-6">
              <div style={{ display: 'flex', padding: '5px 0' }}>
                <div style={{ flex: 1 }}>
                  <img src={recentlyAddedCartItem.image} style={{ width: 62, height: 62, objectFit: 'contain', objectPosition: 'center' }} />
                </div>
                <div style={{ flex: 3, display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                  <strong className="small">{recentlyAddedCartItem.brand?.name}</strong>
                  <span className="small">{recentlyAddedCartItem.product_name}</span>
                  <span className="small">
                    {
                      recentlyAddedCartItem.variation_name ?
                        `Variation: ${recentlyAddedCartItem.variation_name}` : ''
                    }
                    <span className="small" style={{ color: 'rgb(117, 117, 117)', marginLeft: '0.25em' }}>(x{recentlyAddedCartItem.qty})</span>
                  </span>
                  <strong className="small" style={{ marginTop: 15 }}>{formatMoney(recentlyAddedCartItem.cart_item?.price * recentlyAddedCartItem.qty)} MMK</strong>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <p>Basket total ({cartCount} {cartCount > 1 ? 'items' : 'item'}): <strong>{formatMoney(cartTotal)} MMK</strong></p>
              <div onClick={() => { setOpenAddedToBasket(false); history.push('/basket') }} className="button-custom button-checkout">View Basket & Checkout</div>
              <div onClick={() => setOpenAddedToBasket(false)} className="button-custom">Continue Shopping</div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withRouter(MainNavbar);
