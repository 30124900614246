import React, { useState, useContext, useEffect } from 'react'
import '../App.css'
import { Divider, Typography, TextField, Radio, FormControlLabel, RadioGroup, Checkbox } from '@material-ui/core'
import { withRouter, Link } from 'react-router-dom'
import AppContext from '../components/AppContext'
import { formatMoney } from '../components/common'
import { CartItem } from '../components/cart'
import { getData, postData, postFormData } from '../components/fetch'
import moment from 'moment'
import { SimpleDialog } from '../components/dialog'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {  Button } from "@material-ui/core";
import ImageUploader from '../components/ImageUploader'
import ArrayFnc from '../components/array';

const Checkout = ({ history }) => {
  const { token, cartItems, cartTotal, cartCount, ClearBasket } = useContext(AppContext);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address_line_1, setAddress_line_1] = useState('');
  const [address_line_2, setAddress_line_2] = useState('');
  const [zip, setZip] = useState('');
  const [paymentValue, setPaymentValue] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
  const [openCheckoutSuccessModal, setOpenCheckoutSuccessModal] = useState(false);
  const [images, setImages] = useState([])
  const [bank, setBank] = useState('');
  const [banks, setBanks] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [bankValue, setBankValue] = useState('');

  const checkout = async (e) => {
    e.preventDefault();
    if (name && phone && address_line_1 && bankValue && cartItems ) {
      var data = {
        order_date: moment().format('YYYY-MM-DD'),
        name, phone, address_line_1, address_line_2, zip,
        bank_name: bankValue, 
        payment_method_id: paymentId,
        images: images.map(x => x.file),
        order_items: cartItems.map(x => ({
          qty: x.qty,
          price: x.cart_item.price,
          type: x.type,
          orderable_id: x.cart_item.id,
        })) 
      };
      // console.log("data",data)
      var response = await postFormData(token ? `api/v1/customer/orders` : `api/v1/orders`, data);
      // console.log("response",response)
      if (response.ok) {
        ClearBasket();
        setOpenCheckoutSuccessModal(true);
      } else {
        alert(response.data.message || 'Something went wrong. Please try again.')
      }
    }
  }

  const GetBanks = async () => {
    var response = await getData(`api/v1/banks/all`);
    if (response.ok) {
      setBanks(response.data.data);
    }
  }

  const getProfile = async () => {
    var response = await getData('api/v1/customer/user')
      if (response.ok) {
        setName(response.data.data.name)
        // setEmail(response.data.data.email)
        setPhone(response.data.data?.phone || '')
        // setImage(response.data.data.picture)
      }
      var response1 = await getData('api/v1/customer/shipping_addresses')
      if (response1.ok) {
        setAddress_line_1(response1.data.data[0]?.address_line_1 || '')
        setAddress_line_2(response1.data.data[0]?.address_line_2 || '')
        setZip(response1.data.data[0]?.zip || '')
      }
  }
  
  useEffect(() => {
    getProfile();
  }, [token])
  
  useEffect(() => {
    GetBanks();
  }, [])

  const handleChange = (event) => {
    setPaymentValue('');
    setBankValue(event.target.value);
  };

  // const handleChangePayment = (event) => {
  //   console.log('event',event.target)
  //   setPaymentValue(event.target.value);
  // };

  return (
    <div>
      <form onSubmit={checkout}>
        <div className="checkout_navbar" style={{ textAlign: 'center' }}>
          {/* <Typography variant="h3">Checkout</Typography>
         */}
          <Link to="/" >
            <img
              src={require("../assets/images/BeautytipsLogo.jpg")}
              style={{ height: 120 }}
            />
          </Link>
        </div>
        <div className="container" style={{ padding: 20 }}>
          <div className="row">

            <div className="col-lg-8 col-sm-12">

              <div className='header-title'>Checkout</div>

              <div style={{ padding: '10px 0px' }}>
                <Divider style={{ paddingBottom: 1, backgroundColor: 'black' }} />
              </div>
              <div style={{ paddingTop: 10 }}>
                <Typography variant='h6' style={{ fontWeight: 700 }}>1. Shipping Address</Typography>
                <div className='row' style={{ marginTop: 15 }}>
                  <div className="col-md-6">
                    <TextField
                      id="email"
                      variant="filled"
                      label="Full Name"
                      type="text"
                      name="name"
                      fullWidth
                      value={name}
                      onChange={e => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      id="phone"
                      variant="filled"
                      label="Phone"
                      type="text"
                      name="phone"
                      fullWidth
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      id="address_line_1"
                      variant="filled"
                      label="Address Line 1"
                      type="text"
                      name="address_line_1"
                      fullWidth
                      value={address_line_1}
                      onChange={e => setAddress_line_1(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <TextField
                      id="address_line_2"
                      variant="filled"
                      label="Address Line 2 (optional)"
                      type="text"
                      name="address_line_2"
                      fullWidth
                      value={address_line_2}
                      onChange={e => setAddress_line_2(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      id="zip"
                      variant="filled"
                      label="Zip Code"
                      type="text"
                      name="zip"
                      fullWidth
                      value={zip}
                      onChange={e => setZip(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ padding: '10px 0px' }}>
                <Divider style={{ paddingBottom: 1, backgroundColor: 'black' }} />
              </div>
              <div style={{ paddingTop: 10 }}>
                <Typography variant='h6' style={{ fontWeight: 700 }}>2. Payment Method</Typography>

                {/* {
                  banks.map((bank, index) =>
                    <div key={index} className='row' style={{ marginTop: 15, padding: '0 15px' }}>
                      <FormControlLabel
                          control={
                              <Checkbox
                                  checked={selectedBanks.includes(bank.id)}
                                  onChange={(e, value) => {
                                      var _selectedBanks = selectedBanks;
                                      if (_selectedBanks.includes(bank.id)) {
                                          _selectedBanks = ArrayFnc.arrayRemove(_selectedBanks, bank.id);
                                      } else {
                                          _selectedBanks.push(bank.id);
                                      }
                                      setSelectedBanks(ArrayFnc.clone(_selectedBanks));
                                  }}
                                  value={bank.id}
                                  name="checkedB"
                                  color="primary"
                                  size="small"
                                  classes={{ colorPrimary: 'filter-checkbox' }}
                              />
                          }
                          label={bank.name}
                          classes={{
                              label: "filter-label"
                          }}
                          style={{ display: 'block', marginTop: -8, marginBottom: -8, }}
                      />
                        {
                          selectedBanks.includes(bank.id) ?
                            bank.payment_methods.map((payment_method, i) =>                          
                              <div key={i} className='row' style={{ marginTop: 15, padding: '0 15px' }}>
                                  <FormControlLabel 
                                      control={
                                          <Checkbox
                                              checked={selectedPaymentMethods.includes(payment_method.id)}
                                              onChange={(e, value) => {
                                                  var _selectedPaymentMethods = selectedPaymentMethods;
                                                  if (_selectedPaymentMethods.includes(payment_method.id)) {
                                                      _selectedPaymentMethods = ArrayFnc.arrayRemove(_selectedPaymentMethods, payment_method.id);
                                                  } else {
                                                      _selectedPaymentMethods.push(payment_method.id);
                                                  }
                                                  setSelectedPaymentMethods(ArrayFnc.clone(_selectedPaymentMethods));
                                              }}
                                              value={payment_method.id}
                                              name="checkedB"
                                              color="primary"
                                              size="small"
                                              classes={{ colorPrimary: 'filter-checkbox' }}
                                          />
                                      }
                                      label={payment_method.acc_name + ' / ' + payment_method.acc_no}
                                      classes={{
                                          label: "filter-label"
                                      }}
                                      style={{ display: 'block', marginTop: -8, marginBottom: -8, }}
                                  />
                                </div>
                              )
                              :
                              <></>
                        }
                    </div>
                  )
                } */}
                {
                  banks.map((bank, index) =>
                  <div key={index} className='row' style={{ marginTop: 15, padding: '0 15px' }}>
                    <RadioGroup value={bankValue} onChange={handleChange} aria-label="bank" name="customized-radios">
                      <FormControlLabel color="default" value={bank.name} control={<Radio />} label={bank.name} />
                        {
                          bankValue == bank.name ?
                            bank.payment_methods.map((payment_method, i) =>
                            // console.log("dfd",e.target,payment_method.acc_name)
                              <div key={i} className='row' style={{ margin: 20,padding: '0 5px' }}>
                                <RadioGroup value={paymentValue} onChange={(e) =>{ setPaymentValue(e.target.value);setPaymentId(payment_method.id);}} aria-label="payment_method" name="customized-radios">
                                  <FormControlLabel color="default" value={payment_method.acc_name} control={<Radio />} label={payment_method.acc_name + ' / ' + payment_method.acc_no} />
                                </RadioGroup>
                              </div>
                            )
                            :
                            <></>
                        }
                    </RadioGroup>
                    {/* <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                      <FormControlLabel value="female" control={<Radio />} label="Female" />
                      <FormControlLabel value="male" control={<Radio />} label="Male" />
                      <FormControlLabel value="other" control={<Radio />} label="Other" />
                      <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />
                    </RadioGroup> */}
                  </div>
                  )
                }
              </div>
              <div style={{ padding: 10 }}>
                {
                  paymentValue ?
                    <ImageUploader
                      labelText={' Choose Images *'}
                      value={images}
                      onChange={(images, addUpdateIndex, action) => {  setImages(images) }}
                      // errorText={sizeImagesError}
                      // resolutionProps={resolution.product}
                    />
                    :
                    <></>
                }
              {/* <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: '100%', height: 40 }}
                  // onClick={() => AddToBasket({ selectedItem })}
                >
                  Choose File
                </Button> */}
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div style={{
                borderRadius: 4,
                border: '2px solid rgb(238, 238, 238)',
                position: 'sticky',
                top: 0
              }}>
                <div style={{ padding: 16 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="mb-1">Merchandise subtotal</p>
                    <p className="mb-1"><b>{formatMoney(cartTotal)} MMK</b></p>
                  </div>
                  {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="mb-1">Shipping & Handling</p>
                    <p className="mb-1"><b>0 MMK</b></p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="mb-0">Tax</p>
                    <p className="mb-0"><b>0 MMK</b></p>
                  </div> */}
                  <div className="divider"></div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <b className="mb-0">Order Total</b>
                    <p className="mb-0"><b>{formatMoney(cartTotal)} MMK</b></p>
                  </div>
                  <hr />
                  <button className="checkout-btn">Place Order</button>
                </div>
                <div>
                  <div className="item-in-order-header underline-hover">
                    <p className="mb-0">Item in order ({cartCount})</p>
                  </div>
                  <div style={{ padding: 16 }}>
                    <div style={{ textAlign: 'center' }}>
                      <p className="mb-0 underline-hover" onClick={() => history.push('/basket')}>View Basket</p>
                    </div>
                    <hr />
                    <div style={{
                      overflow: 'hidden auto',
                      maxHeight: 200
                    }}>
                      {
                        cartItems.length > 0 ? <>
                          {
                            cartItems.map((cartItem, index) =>
                              <CartItem key={index} cartItem={cartItem} history={history} />
                            )
                          }
                        </> : <></>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
      <SimpleDialog title="Checkout Complete" open={openCheckoutSuccessModal} onClose={() => { setOpenCheckoutSuccessModal(false); history.push('/'); }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 15
        }}>
          <AssignmentTurnedInIcon style={{ fontSize: 50 }} />
          <div>
            <p>Order is successful. Admin will contact you later.</p>
          </div>
          <div onClick={() => { setOpenCheckoutSuccessModal(false); history.push('/'); }} className="button-custom" style={{ maxWidth: 300 }}>Continue Shopping</div>
        </div>
      </SimpleDialog>
    </div>
  )
}

export default withRouter(Checkout)
