import React, { useState, useLayoutEffect, useEffect } from "react";
import "../App.css";
import { Container, Typography, Grid, Button } from "@material-ui/core";
import { ProductCarousel } from "../components/carousel";
import "../assets/styles/carousel.css";
// import { MyDropdown } from "../components/dropdown";
import { getData } from '../components/fetch';
import { Link } from "react-router-dom";

export default function HomePage(props) {
  const [seriesNum, setSeriesNum] = useState(6);
  const [btnSeriesNum, setBtnSeriesNum] = useState(4);
  const [main_poster, setMain_poster] = useState('');
  const [left_home_banner, setLeft_home_banner] = useState('');
  const [right_home_banner, setRight_home_banner] = useState('');
  const [more_beauty_for_you_banner_1, setMore_beauty_for_you_banner_1] = useState('');
  const [more_beauty_for_you_banner_2, setMore_beauty_for_you_banner_2] = useState('');
  const [more_beauty_for_you_banner_3, setMore_beauty_for_you_banner_3] = useState('');
  const [more_beauty_for_you_banner_4, setMore_beauty_for_you_banner_4] = useState('');
  const [main_poster_url, setMain_poster_url] = useState('');
  const [left_home_banner_url, setLeft_home_banner_url] = useState('');
  const [right_home_banner_url, setRight_home_banner_url] = useState('');
  const [more_beauty_for_you_banner_1_url, setMore_beauty_for_you_banner_1_url] = useState('');
  const [more_beauty_for_you_banner_2_url, setMore_beauty_for_you_banner_2_url] = useState('');
  const [more_beauty_for_you_banner_3_url, setMore_beauty_for_you_banner_3_url] = useState('');
  const [more_beauty_for_you_banner_4_url, setMore_beauty_for_you_banner_4_url] = useState('');
  const [new_arrival, setNew_Arrival] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [best_seller, setBest_seller] = useState([]);

  useLayoutEffect(() => {
    setSeriesNum(
      window.innerWidth <= 450 && window.innerHeight >= 200
        ? 2
        : window.innerWidth == 760
          ? 3
          : 6
    );
  }, [window.innerWidth]);

  useLayoutEffect(() => {
    setBtnSeriesNum(
      window.innerWidth <= 450 && window.innerHeight >= 200
        ? 2
        : window.innerWidth == 760
          ? 3
          : 4
    );
  }, [window.innerWidth]);

  const GetBanner = async (type) => {
    var response = await getData(`api/v1/banners?type=${type}`);
    if (response.ok) {
      switch (type) {
        case "main_poster": setMain_poster(response.data.data.image.url); setMain_poster_url(response.data.data.url || "#"); break;
        case "left_home_banner": setLeft_home_banner(response.data.data.image.url); setLeft_home_banner_url(response.data.data.url || "#"); break;
        case "right_home_banner": setRight_home_banner(response.data.data.image.url); setRight_home_banner_url(response.data.data.url || "#"); break;
        case "more_beauty_for_you_banner_1": setMore_beauty_for_you_banner_1(response.data.data.image.url); setMore_beauty_for_you_banner_1_url(response.data.data.url || "#"); break;
        case "more_beauty_for_you_banner_2": setMore_beauty_for_you_banner_2(response.data.data.image.url); setMore_beauty_for_you_banner_2_url(response.data.data.url || "#"); break;
        case "more_beauty_for_you_banner_3": setMore_beauty_for_you_banner_3(response.data.data.image.url); setMore_beauty_for_you_banner_3_url(response.data.data.url || "#"); break;
        case "more_beauty_for_you_banner_4": setMore_beauty_for_you_banner_4(response.data.data.image.url); setMore_beauty_for_you_banner_4_url(response.data.data.url || "#"); break;
      }
    }
  }

  const limitText = (str, start, end) => {
    return str.substring(start, end) + (str.length > end ? "..." : '')
  }

  const GetProducts = async type => {
    var response = await getData(`api/v1/products/type/${type}`);
    if (response.ok) {
      const { images, brand, name, short_description } = response.data.data;
      var data = response.data.data.map(x => ({
        id: x.id,
        image: x.images.length > 0 ? x.images[0].url : '',
        brand: x.brand ? limitText(x.brand?.name || "", 0, 36) : null,
        name: x.name,
        description: x.short_description,
        price: x.price,
        detail: x
      }));
      switch (type) {
        case "new_arrival": setNew_Arrival(data); break;
        case "featured": setFeatured(data); break;
        case "best_seller": setBest_seller(data); break;
      }
    }
  }

  useEffect(() => {
    GetBanner('main_poster');
    GetBanner('left_home_banner');
    GetBanner('right_home_banner');
    GetBanner('more_beauty_for_you_banner_1');
    GetBanner('more_beauty_for_you_banner_2');
    GetBanner('more_beauty_for_you_banner_3');
    GetBanner('more_beauty_for_you_banner_4');

    GetProducts("new_arrival")
    GetProducts("featured")
    GetProducts("best_seller")
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div style={{
              backgroundImage: `url(${main_poster})`,
              width: "100%",
              paddingTop: '41.66%',
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              marginBottom: 25,
              display: 'flex',
            }}>
              <a target="_blank" href={main_poster_url} className="mainPosterLink"></a>
              <a target="_blank" href={main_poster_url} className="mainPosterLink"></a>
              <a target="_blank" href={main_poster_url} className="mainPosterLink"></a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-sm container-lg container-md"
        style={{ paddingLeft: 30, paddingRight: 30 }}
      >
        <div className="container-sm container-lg container-md">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-6"
              style={{ marginBottom: 30 }}
            >
              <a target="_blank" href={left_home_banner_url}>
                <img src={left_home_banner} style={{ width: '100%' }} />
              </a>
            </div>

            <div
              className="col-lg-6 col-md-6 col-sm-6"
              style={{ marginBottom: 30 }}
            >
              <a target="_blank" href={right_home_banner_url}>
                <img src={right_home_banner} style={{ width: '100%' }} />
              </a>
            </div>
          </div>

          <ProductCarousel
            seriesNum={seriesNum}
            name="Selling Fast"
            items={best_seller}
          />
          <ProductCarousel
            seriesNum={seriesNum}
            name="New Arrival"
            items={new_arrival}
          />
          <ProductCarousel
            seriesNum={seriesNum}
            name="Recommended For You"
            items={featured}
          />

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: 48,
            }}
          >
            <div
              style={{ width: "100%", textAlign: "center", marginBottom: 32 }}
            >
              <Typography
                style={{
                  lineHeight: 1,
                  fontFamily: "georgia, times, serif",
                  fontSize: 32,
                }}
              >
                More Beauty For You
              </Typography>
            </div>

            <div id="more_beauty_for_you_banner" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', width: '100%' }}>
              <a target="_blank" href={more_beauty_for_you_banner_1_url}>
                <img src={more_beauty_for_you_banner_1} style={{ marginBottom: 15 }} />
              </a>
              <a target="_blank" href={more_beauty_for_you_banner_2_url}>
                <img src={more_beauty_for_you_banner_2} style={{ marginBottom: 15 }} />
              </a>
              <a target="_blank" href={more_beauty_for_you_banner_3_url}>
                <img src={more_beauty_for_you_banner_3} style={{ marginBottom: 15 }} />
              </a>
              <a target="_blank" href={more_beauty_for_you_banner_4_url}>
                <img src={more_beauty_for_you_banner_4} style={{ marginBottom: 15 }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
