import React, { useState, useContext, useEffect } from 'react'
import '../App.css'
import { Divider, Typography, TextField, Radio, FormControlLabel, RadioGroup } from '@material-ui/core'
import { withRouter, Link } from 'react-router-dom'
import AppContext from '../components/AppContext'
import { getData, postData, postFormData } from '../components/fetch'
import {  Button } from "@material-ui/core";
import ImageUploader, { SingleUpload } from '../components/ImageUploader'

const Profile = ({ history }) => {
  const { token,userId } = useContext(AppContext);
  const [user, setUser] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState('');
  const [address_line_1, setAddress_line_1] = useState('');
  const [address_line_2, setAddress_line_2] = useState('');
  const [zip, setZip] = useState('');
  // const [openCheckoutSuccessModal, setOpenCheckoutSuccessModal] = useState(false);

  const profile = async (e) => {
    e.preventDefault();
    setImageError((!image || image.length <= 0) ? 'This field is required' : '')
    if (name && phone && email && image && address_line_1 && zip) {
      var data = { name, phone, email,picture: image.file };
      var data1 = { userId,phone,address_line_1, address_line_2, zip}
      var response = await postFormData(`api/v1/customer/user` , data);
      var response1 = await postData(`api/v1/customer/shipping_addresses` , data1);
      if (response.ok && response1.ok) {
        alert('Profile is saved successfully.')
      } else {
        alert(response.data.message || 'Something went wrong. Please try again.')
      }
    }
  }

  useEffect(() => {
    getUser();
  }, [token])
  
  const getUser = async () => {
      var response = await getData('api/v1/customer/user')
      if (response.ok) {
        setName(response.data.data.name)
        setEmail(response.data.data.email)
        setPhone(response.data.data.phone)
        setImage(response.data.data.picture)
      }
      var response1 = await getData('api/v1/customer/shipping_addresses')
      if (response1.ok) {
        setAddress_line_1(response1.data.data[0]?.address_line_1 || '')
        setAddress_line_2(response1.data.data[0]?.address_line_2 || '')
        setZip(response1.data.data[0]?.zip || '')
      }
  }

  return (
    <div className="container">
      <form onSubmit={profile}>
        <div className="container" style={{ padding: 20 }}>
          <div className="row">

            <div className="col-lg-8 col-sm-12">

              <div className='header-title'>Profile</div>

              <div style={{ padding: '10px 0px' }}>
                <Divider style={{ paddingBottom: 1, backgroundColor: 'black' }} />
              </div>
              <div style={{ paddingTop: 10 }}>
                <div style={{ padding: 10 }}>
                  <SingleUpload value={image} onChange={image => setImage(image)} dataURLKey="url" errorText={imageError}  />
                </div>
                  <div className="col-md-12" style={{ padding: 10 }}>
                    <TextField
                      id="email"
                      variant="filled"
                      label="Full Name"
                      type="text"
                      name="name"
                      fullWidth
                      value={name}
                      onChange={e => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-12" style={{ padding: 10 }}>
                    <TextField
                      id="email"
                      variant="filled"
                      label="Email"
                      // type="text"
                      name="email"
                      fullWidth
                      value={email}
                      // onChange={e => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-12" style={{ padding: 10 }}>
                    <TextField
                      id="phone"
                      variant="filled"
                      label="Phone"
                      type="text"
                      name="phone"
                      fullWidth
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                      required
                    />
                  </div><div className="col-md-12"  style={{ padding: 10 }}>
                    <TextField
                      id="address_line_1"
                      variant="filled"
                      label="Address Line 1"
                      type="text"
                      name="address_line_1"
                      fullWidth
                      value={address_line_1}
                      onChange={e => setAddress_line_1(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-12"  style={{ padding: 10 }}>
                    <TextField
                      id="address_line_2"
                      variant="filled"
                      label="Address Line 2 (optional)"
                      type="text"
                      name="address_line_2"
                      fullWidth
                      value={address_line_2}
                      onChange={e => setAddress_line_2(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4"  style={{ padding: 10 }}>
                    <TextField
                      id="zip"
                      variant="filled"
                      label="Zip Code"
                      type="text"
                      name="zip"
                      fullWidth
                      value={zip}
                      onChange={e => setZip(e.target.value)}
                      required
                    />
                  </div>
                  {/* <div className="col-md-12">
                    <TextField
                      id="address_line_1"
                      variant="filled"
                      label="Address Line 1"
                      type="text"
                      name="address_line_1"
                      fullWidth
                      value={address_line_1}
                      onChange={e => setAddress_line_1(e.target.value)}
                      required
                    />
                  </div> */}
                </div>
              {/* </div> */}
              <div className="col-md-12">
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: '100%', height: 40 }}
                  type='submit'
                >
                  Save Changes
                </Button>    
              </div>
            </div>
          </div>

        </div>
      </form>
      {/* <SimpleDialog title="Profile Change" open={openCheckoutSuccessModal} onClose={() => { setOpenCheckoutSuccessModal(false); history.push('/'); }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 15
        }}>
          <AssignmentTurnedInIcon style={{ fontSize: 50 }} />
          <div>
            <p>Save Info is successful. </p>
          </div>
          <div onClick={() => { setOpenCheckoutSuccessModal(false); history.push('/'); }} className="button-custom" style={{ maxWidth: 300 }}>Ok</div>
        </div>
      </SimpleDialog> */}
    </div>
  )
}

export default withRouter(Profile)
