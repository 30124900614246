import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import '../App.css';
import "../assets/styles/carousel.css";
import "../assets/styles/dropdown.css";
import { color } from "./common";
import DrawerComponent from "./DrawerComponent";
import { getData } from "./fetch";
import { NavDropdown } from "./navDropdown";

export default function NavCategory(props) {
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const GetCategories = async () => {
    var response = await getData(`api/v1/categories/all`);
    if (response.ok) {
      setCategories(response.data.data);
    }
  }
  useEffect(() => {
    GetCategories();
  }, [])
  return (
    // <div style={{ margin: 0, padding: 0, width: 1903, height: 44 }}>
    <>
      {
        isMobileView ?
          <DrawerComponent categories={categories} />
          :
          <div style={{ backgroundColor: color.primary }}>
            {/* <div style={{ position: "relative", background: "#000", color: "#fff" }}>
       */}
            <div
              className="container category"
              style={{ maxWidth: 1248, padding: 0 }}
            >
              <div
                className="row mynav"
                style={{
                  justifyContent: "space-between",
                  height: 41,
                  margin: 0,
                }}
              >
                {
                  categories.map((category, index) =>
                    <div key={index}>
                      <NavDropdown titleComponent={<CategoryName category={category} />} dropDownStyle={{
                        width: '100%',
                        left: 0,
                        top: 140,
                      }}>
                        <div className="navCategoryBox">
                          <div className="container">
                            <div style={{ margin: '0 -62px', padding: '24px 0', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxHeight: 294 }}>
                              {
                                category.sub_categories.map((sub_category, sub_index) =>
                                  <Link className="sub_category_link" to={`/bysubcategory/${category.name}/${sub_category.name}/${sub_category.id}/products/`} key={sub_index}>{sub_category.name}</Link>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </NavDropdown>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
      }
    </>
  );
}

const CategoryName = (props) => {
  const { category } = props;
  return (
    // <div style={{ display: "flex", height: "44", position: "static" }}>
    <Link className="category_link" to={`/bycategory/${category.name}/${category.id}/products/`}>
      <div
        style={{
          display: "flex",
          position: "static",
        }}>
        <div
          className="dropdown profile"
          style={{
            color: "white",
            fontSize: 14,
            alignItems: "center",
            lineHeight: 1.25,
            paddingTop: 12,
            paddingRight: 8,
            paddingBottom: 12,
            paddingLeft: 8,
            textAlign: "center",
            cursor: 'pointer',
            ...props.style
          }}
        >
          {category.name}
        </div>
      </div>
    </Link>
  );
};
