// import logo from "./logo.svg";
import React from "react";
import "./App.css";
import MainNavbar from "../src/components/mainnavbar";
import ScrollToTop from "../src/components/scrollToTop";
import AboutSubject from "./components/subject";
import HomePage from "./pages/homepage";
import ItemPage from "./pages/productWithItems";
import BasketPage from "./pages/basket";
import Footer from "./components/footer";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProductList from "./pages/productList";
import Location from "./pages/Location";
import VerifyEmail from "./pages/verifyEmail";
import { AppContextProvider } from "./components/AppContext";
import Checkout from "./pages/checkout";
import Profile from "./pages/profile";
import OrderHistory from "./pages/orderHistory";
import OrderDetail from "./pages/orderDetail";

function App({ history }) {
  const Wrapper = ({ children }) => (
    <>
      <ScrollToTop />
      <MainNavbar />
      <AboutSubject />
      {children}
      <Footer />
    </>
  )

  const Wrapper2 = ({ children }) => (
    <>
      {children}
    </>
  )

  return (
    <AppContextProvider>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/">
              <Wrapper>
                <HomePage />
              </Wrapper>
            </Route>
            <Route path="/products">
              <Wrapper>
                <ProductList />
              </Wrapper>
            </Route>
            <Route path="/bybrand/:brand_name/:brand_id">
              <Wrapper>
                <ProductList />
              </Wrapper>
            </Route>
            <Route path="/bycategory/:category_name/:category_id/products">
              <Wrapper>
                <ProductList />
              </Wrapper>
            </Route>
            <Route path="/bysubcategory/:category_name/:sub_category_name/:sub_category_id/products">
              <Wrapper>
                <ProductList />
              </Wrapper>
            </Route>
            <Route path="/product/:id">
              <Wrapper>
                <ItemPage />
              </Wrapper>
            </Route>
            <Route path="/basket">
              <Wrapper>
                <BasketPage />
              </Wrapper>
            </Route>
            <Route path="/checkout">
              <Wrapper2>
                <Checkout />
              </Wrapper2>
            </Route>
            <Route path="/profile">
              <Wrapper>
                <Profile />
              </Wrapper>
            </Route>
            <Route path="/location">
              <Wrapper>
                <Location />
              </Wrapper>
            </Route>
            <Route path="/verifyEmail">
              <Wrapper>
                <VerifyEmail />
              </Wrapper>
            </Route>
            <Route exact path="/MyAccount/orders">
              <Wrapper>
                <OrderHistory />
              </Wrapper>
            </Route>
            <Route path="/MyAccount/orders/:id">
              <Wrapper>
                <OrderDetail />
              </Wrapper>
            </Route>
          </Switch>
        </div>
      </Router>
    </AppContextProvider>
  );
}

export default App;
