import React, { useState } from "react";
import "../App.css";
export const ProfileDropdown = (props) => {
  return (
    <div
      className="innerLink"
      style={{
        ...props.style,
        outline: 0,
        display: "flex",
        webkitBoxAlign: "center",
        alignItems: "center",
        textDecorationLine: "none",
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          backgroundImage: `url(${props.imaged})`,
          width: 24,
          height: 24,
          display: "inline-block",
          maxWidth: "100%",
          backgroundSize: "cover",
          marginRight: 12,
        }}
      ></div>
      <div style={{ display: "block", flex: 1 }}>
        <p style={{ color: "#000", marginBottom: 0 }}>{props.eventsHeader}</p>
        <span
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            display: "block",
            marginTop: ".25em",
            color: "#757575",
            fontSize: 12,
          }}
        >
          {props.About}
        </span>
      </div>
    </div>

  )
}


export const NavDropdown = (props) => {
  const [open, setOpen] = useState(false);
  return (

    <div style={{ display: "flex", cursor: 'pointer' }}
      onMouseLeave={() => setOpen(false)}
      onClick={props.onClick}
    >
      <div
        style={{ flexDirection: "row", display: "flex", alignItems: "center", cursor: 'pointer' }}
        onMouseOver={() => setOpen(true)}
        onClick={() => {
          if(props.onClick) {
            props.onClick();
          }
        }}
      >
        {
          props.titleComponent ? props.titleComponent :
            <>
              {
                props.icon && props.icons ?
                  open ? (
                    <div className="store-Logo ">
                      <img src={props.icon} style={{ ...props.style }} />
                    </div>
                  ) : (
                      <div className="store-Logo">
                        <img src={props.icons} style={{ ...props.style }} />
                      </div>
                    ) : <></>
              }
              {window.innerWidth >= 1201 ? (
                <div style={{}}>
                  <div style={{ fontSize: 14, textAlign: "left", overflowWrap: "break-word" }}>
                    {props.header}
                  </div>
                  <div style={{ fontSize: 12, textAlign: "left" }}>
                    {props.subheader}
                  </div>
                </div>
              ) : (
                  <></>
                )}
            </>
        }
      </div>
      {open ? (
        <div
          // onMouseLeave={() => setOpen(false)}
          style={{
            width: 343,
            height: "auto",
            // borderWidth: 1,
            // borderColor: "#eee",
            borderRadius: 8,
            textAlign: "left",
            position: "absolute",
            top: 99,
            right: 0,
            zIndex: 1130,
            ...props.dropDownStyle
          }}
        >

          {props.children}

        </div>
      ) : (
          <></>
        )}
    </div>

  );
};
