import React, { createContext, useState, useEffect, useMemo } from 'react'
import { setToken as setTokenToLocalStorage, getToken, removeToken } from './storage';
import useDidUpdateEffect from './hooks/useDidUpdateEffect';
import { deleteData, getData, postData, putData } from './fetch';
import ArrayFnc from './array';

const AppContext = createContext();
export default AppContext;

const actionEnum = {
    addToBasket: 1,
    addToLoves: 2,
}
Object.freeze(actionEnum);

const defaultAction = {
    type: '',
    params: {},
};

export const AppContextProvider = props => {
    const [token, setToken] = useState(null);
    const [openSignIn, setOpenSignIn] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [openAddedToBasket, setOpenAddedToBasket] = useState(false);
    const [cartCount, setCartCount] = useState(0);
    const [cartItems, setCartItems] = useState([]);
    const [recentlyAddedCartItem, setRecentlyAddedCartItem] = useState({});
    const [action, setAction] = useState(defaultAction);
    const [cartTotal, setCartTotal] = useState(0);
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [userImage, setUserImage] = useState('');

    var getTokenFromLocalStorage = async () => {
        var ls_token = await getToken();
        setToken(ls_token);
    }

    const getLocalStorage = e => {
        // console.log(e.key, e.oldValue, e.newValue, e.url, e.storageArea);
        if (e.key === 'beautytips_token') {
            setToken(e.newValue);
            if (!e.newValue) {
                setCartCount(0);
            }
        }
    }

    const CartItemWithAdditionalInfo = (cartItem) => {
        var product = cartItem.type === 'size' ? cartItem.cart_item.variation.product : cartItem.type === "variation" ? cartItem.cart_item.product : cartItem.cart_item;
        var variation = cartItem.type === 'size' ? cartItem.cart_item.variation : cartItem.type === "variation" ? cartItem.cart_item : null;
        var size = cartItem.type === 'size' ? cartItem.cart_item : null;
        return {
            ...cartItem,
            image: product?.images[0].url || '',
            brand: product?.brand || '',
            product_id: product?.id || '',
            product_name: product?.name || '',
            sku: product?.sku || '',
            variation_name: variation?.color || '',
            size_name: size?.name || '',
        }
    }

    const getCartItems = async () => {
        if (token) {
            var response = await getData(`api/v1/customer/cart-items/all`);
            if (response.ok) {
                setCartItems(response.data.data.map(x => CartItemWithAdditionalInfo(x)));
            }
        }
    }

    const getUser = async () => {
        if (token) {
            var response = await getData('api/v1/customer/user')
            // console.log("respon",response)
            if (response.ok) {
                setUserName(response.data.data.name)
                setUserId(response.data.data.id)
                setUserImage(response.data.data.picture?.url || '')
            }
        }
    }

    useEffect(() => {
        if (cartItems.length > 0) {
            setCartCount(ArrayFnc.sum(cartItems, 'qty'));
            var total = 0;
            for (var i = 0; i < cartItems.length; i++) {
                total += cartItems[i].cart_item.price * cartItems[i].qty;
            }
            setCartTotal(total);
        } else {
            setCartCount(0);
            setCartTotal(0);
        }
    }, [cartItems])

    useEffect(() => {
        getTokenFromLocalStorage();
        window.addEventListener('storage', getLocalStorage);

        return () => window.removeEventListener('storage', getLocalStorage);
    }, [])

    useEffect(() => {
        getUser();
    }, [token])

    useDidUpdateEffect(() => {
        // console.log(recentlyAddedCartItem)
        setOpenAddedToBasket(true);
    }, [recentlyAddedCartItem])

    const AddToBasket = async (data) => {
        const { selectedItem = {}, fnc = undefined } = data;
        if (selectedItem.qty > selectedItem.stock) {
            if (selectedItem.stock === 0) {
                alert('This item is out of stock.')
            } else {
                alert(`Only ${selectedItem.stock} item${selectedItem.stock > 1 ? 's' : ''} left in stock. Please adjust the quantity and try again.`)
            }
            if (fnc) {
                fnc(false);
            }
        } else if (selectedItem.qty > 0 && selectedItem.type && selectedItem.id) {
            if (token) {
                var response = await postData(`api/v1/customer/cart-items`, selectedItem);
                if (response.ok) {
                    setRecentlyAddedCartItem(CartItemWithAdditionalInfo(response.data.data));
                    getCartItems();
                    if (fnc) {
                        fnc(true);
                    }
                }
            } else {
                setAction({ type: actionEnum.addToBasket, params: data });
                setOpenSignIn(true);
                fnc(false);
            }
        }
    }

    const UpdateBasketItem = async (id, qty, fnc = undefined) => {
        var response = await putData(`api/v1/customer/cart-items/${id}`, { qty });
        if (response.ok) {
            getCartItems();
            if (fnc) {
                fnc();
            }
        }
    }

    const RemoveBasketItem = async (id, fnc = undefined) => {
        var response = await deleteData(`api/v1/customer/cart-items/${id}`);
        if (response.ok) {
            getCartItems();
            if (fnc) {
                fnc();
            }
        }
    }

    const ClearBasket = async (fnc = undefined) => {
        var response = await postData(`api/v1/customer/cart-items/clear`);
        if (response.ok) {
            getCartItems();
            if (fnc) {
                fnc();
            }
        }
    }

    useEffect(() => {
        if (token) {
            if (action) {
                switch (action.type) {
                    case actionEnum.addToBasket:
                        AddToBasket(action.params);
                        break;
                    case actionEnum.addToLoves: break;
                    default: getCartItems(); break;
                }
                setAction(defaultAction);
            } else {
                getCartItems();
            }
        }
    }, [token])

    useDidUpdateEffect(() => {
        if (!token) {
            removeToken();
        }
    }, [token])

    const appContext = useMemo(() => ({
        token,
        setToken: async (token) => {
            await setTokenToLocalStorage(token);
            setToken(token);
        },
        openSignIn,
        setOpenSignIn,
        openRegister,
        setOpenRegister,
        openAddedToBasket,
        setOpenAddedToBasket,
        recentlyAddedCartItem,
        cartCount,
        cartTotal,
        cartItems,
        refreshCart: () => getCartItems(),
        AddToBasket,
        UpdateBasketItem,
        RemoveBasketItem,
        ClearBasket,
        userId,
        userName,
        userImage,
    }), [token, openSignIn, userId, userName, userImage, openRegister, openAddedToBasket, recentlyAddedCartItem, cartCount, cartTotal, cartItems])

    return (
        <AppContext.Provider value={appContext}>
            {props.children}
        </AppContext.Provider>
    )
}
