import { Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useParams, withRouter, Link } from 'react-router-dom';
import { font, formatMoney } from '../components/common';
import { getData } from '../components/fetch';
import moment from 'moment';

const OrderDetail = (props) => {
    const { id } = useParams();
    const [order, setOrder] = useState(undefined);
    const OrderItemWithAdditionalInfo = (orderItem) => {
        var product = orderItem.type === 'size' ? orderItem.orderable_item.variation.product : orderItem.type === "variation" ? orderItem.orderable_item.product : orderItem.orderable_item;
        var variation = orderItem.type === 'size' ? orderItem.orderable_item.variation : orderItem.type === "variation" ? orderItem.orderable_item : null;
        var size = orderItem.type === 'size' ? orderItem.orderable_item : null;
        return {
            ...orderItem,
            image: product?.images[0].url || '',
            brand: product?.brand || '',
            product_id: product?.id || '',
            product_name: product?.name || '',
            sku: product?.sku || '',
            variation_name: variation?.color || '',
            size_name: size?.name || '',
        }
    }
    const GetOrder = async (id) => {
        var response = await getData(`api/v1/customer/orders/${id}`);
        if (response.ok) {
            setOrder(response.data.data);
        }
    }
    useEffect(() => {
        GetOrder(id);
    }, [id])
    return (
        <div className="container" style={{ padding: 20 }}>
            <div className='header-title'>Order Detail</div>
            <div style={{ padding: '10px 0px' }}>
                <Divider style={{ paddingBottom: 1, backgroundColor: 'black' }} />
            </div>
            <div className='table-responsive'>
                <table border='0'>
                    <tr style={{ height: 30 }}>
                        <td width={150}><b style={{ marginRight: 30 }}>Order Date</b></td>
                        <td>{order ? moment(order.order_date).format('MMMM DD, YYYY') : ''}</td>
                    </tr>
                    <tr style={{ height: 30 }}>
                        <td width={150}><b style={{ marginRight: 30 }}>Order No</b></td>
                        <td>{order?.id}</td>
                    </tr>
                    <tr style={{ height: 30 }}>
                        <td width={150}><b style={{ marginRight: 30 }}>Status</b></td>
                        <td>{order?.status}</td>
                    </tr>
                    <tr style={{ height: 30 }}>
                        <td width={150}><b style={{ marginRight: 30 }}>Shipping To</b></td>
                        <td>{order?.address_line_1}, {order?.address_line_2}</td>
                    </tr>
                    <tr style={{ height: 30 }}>
                        <td width={150}><b style={{ marginRight: 30 }}>Zipcode</b></td>
                        <td>{order?.zip}</td>
                    </tr>
                    <tr style={{ height: 30 }}>
                        <td width={150}><b style={{ marginRight: 30 }}>Payment Method</b></td>
                        <td>{order?.bank_name}</td>
                    </tr>
                </table>
            </div>
            {/* <div>
                <Divider style={{ paddingBottom: 3, backgroundColor: '#ccc' }} />
            </div> */}
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{ paddingLeft: 0 }}>Item</th>
                            <th width={200}>Price</th>
                            <th width={80}>Qty</th>
                            <th width={200}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            order && order.order_items.map(x => OrderItemWithAdditionalInfo(x)).map((order_item, index) =>
                                <tr key={index}>
                                    <td>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <img src={order_item.image} style={{ width: 97, height: 97, objectFit: 'contain', objectPosition: 'center' }} />
                                            </div>
                                            <div style={{
                                                padding: '0px 15px',
                                                flexBasis: "0%",
                                                flex: 1,
                                                display: "block",
                                            }}
                                            >
                                                <div style={{ display: "block" }}>
                                                    <Link
                                                        style={{
                                                            display: "block",
                                                            cursor: "pointer",
                                                            outline: 0,
                                                            color: "inherit",
                                                        }}
                                                        to={`/product/${order_item.product_id}`}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "block",
                                                                lineHeight: 1.25,
                                                                fontSize: 14,
                                                                fontFamily: font.primary,
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "block",
                                                                    fontWeight: 700,
                                                                    textTransform: "uppercase",
                                                                }}
                                                            >
                                                                {order_item.brand.name}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: "block",
                                                                }}
                                                            >
                                                                {order_item.product_name}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div
                                                    style={{
                                                        display: "block",
                                                        marginTop: 4,
                                                        fontSize: 12,
                                                        lineHeight: 1.25,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "block",
                                                            color: "rgb(117, 117, 117)",
                                                        }}
                                                    >
                                                        {order_item.sku}
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "block",
                                                            overflowWrap: "break-word",
                                                            marginTop: 4,
                                                            lineHeight: 1.25,
                                                        }}
                                                    >
                                                        {
                                                            order_item.variation_name ? `COLOR : ${order_item.variation_name}` : ''
                                                        }
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "block",
                                                            overflowWrap: "break-word",
                                                            marginTop: 4,
                                                            lineHeight: 1,
                                                        }}
                                                    >
                                                        {
                                                            order_item.size_name ? `SIZE : ${order_item.size_name}` : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{formatMoney(order_item.price)} MMK</td>
                                    <td>{order_item.qty}</td>
                                    <td>{formatMoney(order_item.price * order_item.qty)} MMK</td>
                                </tr>
                            )
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4">

                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}

export default withRouter(OrderDetail);