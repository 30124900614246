import React, { useEffect, useRef, useState } from "react";
import ImageUploading from "react-images-uploading";
import { Add as AddIcon, AddAlert, Remove as RemoveIcon } from '@material-ui/icons';
import '../assets/css/imageUpload.css';
// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";
import { InputLabel } from "@material-ui/core";
import useDidUpdateEffect from "./hooks/useDidUpdateEffect";
import Button from "@material-ui/core/Button";
// import Snackbar from "components/Snackbar/Snackbar.js";

export default function ImageUploader(props) {
    const { resolutionProps } = props;
    const [images, setImages] = useState(props.value);
    const [action, setAction] = useState(null);
    const maxNumber = 69;
    const { minNumber = 1 } = props;
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        // console.log(imageList, addUpdateIndex);
        var oldImages = [];
        if (props.onImageUpdate || props.onImageUpload) {
            oldImages = JSON.parse(JSON.stringify(images));
        }
        setImages(imageList);
        props.onChange(imageList, addUpdateIndex, action);
        if (action && action.status == "update" && props.onImageUpdate) {
            props.onImageUpdate(addUpdateIndex.map(x => ({ old: oldImages[x], new: imageList[x] })));
        } else if (action && action.status == "add" && props.onImageUpload) {
            props.onImageUpload(addUpdateIndex.map(x => imageList[x]));
        }
        setAction(null);
    };

    useEffect(() => {
        if (action) {
            action.fnc(action.index);
        }
    }, [action])

    // const resolutionProps = {
    //     resolutionType: 'ratio',
    //     resolutionWidth: resolutionWidth,
    //     resolutionHeight: resolutionHeight
    // }

    return (
        <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey={props.dataURLKey || "data_url"}
            {...resolutionProps}
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
                errors
            }) => (
                // write your building UI
                // <div className="upload__image-wrapper">
                //     <div xs={12} sm={12} md={6}>
                //         <div
                //             className='image-container add'
                //             onClick={() => setAction({ status: 'add', fnc: onImageUpload, index: null })}
                //             {...dragProps}
                //         >
                //             <span style={{ position: 'relative', top: '39%' }}>
                //                 {props.labelText ? props.labelText : 'Images - Click / Drop here'}
                //                 {
                //                     resolutionProps && resolutionProps.resolutionWidth && resolutionProps.resolutionHeight ?
                //                         ` (${resolutionProps.resolutionWidth}x${resolutionProps.resolutionHeight})` : ''
                //                 }
                //             </span>
                //         </div>
                //         {errors && <div style={{ marginTop: -20, marginBottom: 20 }}>
                //             {errors.maxNumber && <InputLabel error shrink>Number of selected images exceed maxNumber</InputLabel>}
                //             {errors.acceptType && <InputLabel error shrink>Your selected file type is not allow</InputLabel>}
                //             {errors.maxFileSize && <InputLabel error shrink>Selected file size exceed maxFileSize</InputLabel>}
                //             {errors.resolution && <InputLabel error shrink>Selected file is not match your desired resolution</InputLabel>}
                //         </div>}
                //     </div>
                // <div>

                <div className="upload__image-wrapper">
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ width: '100%', height: 40 ,marginBottom: 10}}
                        onClick={() => setAction({ status: 'add', fnc: onImageUpload, index: null })}
                        {...dragProps}
                    >
                        {props.labelText} 
                    </Button>
                    <div className='row'>
                        {imageList.map((image, index) => {
                            return (
                                <div key={index} className='row' className="image-item image-container" onClick={() => setAction({ status: 'update', fnc: onImageUpdate, index })} style={{ margin: 10, backgroundImage: `url(${image[props.dataURLKey || "data_url"] ? image[props.dataURLKey || "data_url"] : image.data_url ? image.data_url : image})` }}>
                                    {/* {
                                                index > (minNumber - 1) ? */}
                                    <div className='icon-round' onClick={(e) => {
                                        if (!e) e = window.event;
                                        e.cancelBubble = true;
                                        if (e.stopPropagation) e.stopPropagation();
                                        onImageRemove(index);
                                        if (props.onImageRemove) {
                                            props.onImageRemove(image);
                                        }
                                    }}><RemoveIcon style={{ fontSize: 12 }} /></div> : <></>
                                    {/* } */}
                                </div>
                            )
                            })
                        }
                    </div>
                    {props.errorText !== undefined ? (
                            <InputLabel error shrink>{props.errorText}</InputLabel>
                        ) 
                        : 
                        null
                    }
                </div>
                )
            }
        </ImageUploading >
    );
}

export function SingleUpload(props) {
    const { resolutionProps } = props;
    const [image, setImage] = useState(props.value)
    const [isDefault, setIsDefault] = useState(true);
    const [openAlert, setOpenAlert] = useState(false);
    const fileRef = useRef(null);
    const handleOpen = () => {
        fileRef.current.click();
    }
    const onChange = async (e) => {
        var file = e.target.files[0];
        var isVerified = true;
        if (resolutionProps) {
            isVerified = await verifyFileResolution(file, resolutionProps);
        }
        if (isVerified) {
            getBase64(file).then(data_url => {
                setImage({
                    file,
                    data_url
                });
            })
        } else {
            setOpenAlert(true);
        }
    }

    useEffect(() => {
        setImage(props.value);
        // if (props.value && isDefault) {
        //     setImage(props.value);
        //     setIsDefault(false);
        // }
    }, [props.value])

    useDidUpdateEffect(() => {
        if (props.onChange)
            props.onChange(image);
    }, [image])

    return (
        <div>
            <input type='file' ref={fileRef} onChange={onChange} onClick={e => e.target.value = null} hidden />
            <InputLabel shrink>
                {
                    props.labelText
                }
                {
                    resolutionProps && resolutionProps.resolutionWidth && resolutionProps.resolutionHeight &&
                    (props.labelText ? ' (' : '* ') + `${resolutionProps.resolutionWidth}x${resolutionProps.resolutionHeight}` + (props.labelText ? ')' : '')
                }
            </InputLabel>
            {
                image ?
                    <div className="image-item image-container" onClick={handleOpen} style={{ ...props.style, backgroundImage: `url(${image[props.dataURLKey || "data_url"] ? image[props.dataURLKey || "data_url"] : image.data_url ? image.data_url : image})` }}>
                        {
                            props.unremoveable ? <></> :
                                <div className='icon-round' style={props.removeIconStyle} onClick={(e) => {
                                    if (!e) e = window.event;
                                    e.cancelBubble = true;
                                    if (e.stopPropagation) e.stopPropagation();
                                    setImage(null);
                                }}><RemoveIcon style={{ fontSize: 12 }} /></div>
                        }
                    </div>
                    :
                    <div className='image-container flex-center' style={{ boxShadow: 'none', border: '2px dashed #eeeeee', color: '#eeeeee', ...props.style }} onClick={handleOpen}>
                        <AddIcon />
                    </div>
            }
            {props.errorText ? (
                <InputLabel error shrink>{props.errorText}</InputLabel>
            ) : null}
            {/* {console.log("Selected file is not match your desired resolution.")} */}
            {/* <Snackbar
                place="tc"
                color="warning"
                // icon={AddAlert}
                message="Selected file is not match your desired resolution."
                open={openAlert}
                closeNotification={() => setOpenAlert(false)}
                close
            /> */}
        </div>
    )
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export async function verifyFileResolution(file, resolutionProps) {
    return new Promise((resolve, reject) => {
        if (file && resolutionProps) {
            var img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = function () {
                var width = this.naturalWidth;
                var height = this.naturalHeight;
                var type = resolutionProps.resolutionType;
                var resolutionWidth = resolutionProps.resolutionWidth;
                var resolutionHeight = resolutionProps.resolutionHeight;
                var isVerified = false;
                switch (type) {
                    case 'absolute': isVerified = width === resolutionWidth && height === resolutionHeight; break;
                    case 'ratio': isVerified = (resolutionWidth / resolutionHeight) === (width / height); break;
                    case 'less': isVerified = width < resolutionWidth && height < resolutionHeight; break;
                    case 'more': isVerified = width > resolutionWidth && height > resolutionHeight; break;
                }
                resolve(isVerified);
            };
            img.onerror = error => reject(error);
        } else {
            return true;
        }
    });
}